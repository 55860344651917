/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ClusterCardList from './ClusterCardList';
import DeleteIcon from '../../components/DeleteIcon/DeleteIcon';
import EditIcon from '../../components/EditIcon/EditIcon';

import css from './EditListingClusterForm.module.css';

const ClusterListingForm = (props) => {
    const {
        intl,
        clusters,
        addOns,
        onCreateNewCluster,
        onEditCluster,
        onManageDisableScrolling,
        onDeleteCluster,
    } = props;

    const clusterCardActions = (_clus) => {
        return (
            <div className={css.addOnHeadingContainer}>
                <div className={css.customAddonLabel}></div>
                <div className={css.addOnActionsContainer}>
                    <a
                        className={css.actionText}
                        type="button"
                        onClick={() => onEditCluster(_clus.uuid)}>
                        <EditIcon />
                    </a>

                    {clusters.length !== 1 && (
                        <>
                            <span
                                className={css.addOnActionTextSeparator}></span>
                            <a
                                className={classNames(css.actionTextDelete)}
                                onClick={() => onDeleteCluster(_clus)}>
                                <DeleteIcon />
                            </a>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const newAddActivityBtnLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.newAddActivityBtnLabel',
    });

    return (
        <div>
            <p>
                <FormattedMessage id="EditListingAddOnForm.clusterTabDescription" />
                <a href="https://robin-room.com/activity-types/" target="_blank">
                    <FormattedMessage id="EditListingAddOnForm.learnMoreText" />
                </a>
            </p>
            <ClusterCardList
                clusters={clusters}
                addOns={addOns}
                actions={clusterCardActions}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
            />
            <div style={{ display: 'flex' }}>
                <a
                    className={css.customAddonBtn}
                    onClick={() => onCreateNewCluster()}>
                    {newAddActivityBtnLabel}
                </a>
            </div>
        </div>
    );
};

ClusterListingForm.propTypes = {};

export default compose(injectIntl)(ClusterListingForm);
