import React, { useState } from 'react';
import { Modal, Button, FieldTextInput } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CancelInquiryModal.module.css';
import { FieldSelect } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import { updateMetadata } from '../../util/api';
import { DeclineOrCancelOptions } from '../../util/misc';

const CancelInquiryModal = (props) => {
    const { isOpen, onClose, intl, txId, onManageDisableScrolling, submitG4 } =
        props;

    const [reasonRequired, setReasonRequired] = useState(false);

    const onCancel = () => {
        onClose();
    };

    const formatAndSubmit = (values) => {
        onCancel();
        updateMetadata({
            id: txId,
            metaParams: {
                cancellationReason: reasonRequired
                    ? values.reasonOfCancellation
                    : values.cancelOption,
            },
        })
            .then((res) => {
                // if (typeof window !== 'undefined') {
                //     window.location.reload();
                // }
                submitG4('cancel');
            })
            .catch((err) => console.log('err', err));
    };
    return (
        <div className={css.root}>
            <Modal
                id="CancelInquiryModal"
                isOpen={isOpen}
                onClose={onCancel}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                usePortal>
                <FinalForm
                    {...props}
                    onSubmit={(e) => formatAndSubmit(e)}
                    onChange={(formState) =>
                        console.log('form values changed to:', formState.values)
                    }
                    render={(fieldRenderProps) => {
                        const { handleSubmit, invalid, pristine, submitting } =
                            fieldRenderProps;
                        const required = validators.required(
                            'This field is required'
                        );
                        const submitDisabled =
                            invalid || pristine || submitting;
                        return (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}>
                                <h2 className={css.title}>
                                    <FormattedMessage id="CancelInquiryModal.title" />
                                </h2>
                                <FieldSelect
                                    id="cancelOption"
                                    name="cancelOption"
                                    onChange={(e) => {
                                        e === 'Other (Please specify)'
                                            ? setReasonRequired(true)
                                            : setReasonRequired(false);
                                    }}
                                    label="">
                                    <option value="">Select options</option>
                                    {DeclineOrCancelOptions.map(option => {
                                        return <option value={option}>
                                            {option}
                                        </option>
                                    })}
                                    <option value="Other (Please specify)">
                                        Other (Please specify)
                                    </option>
                                </FieldSelect>
                                {reasonRequired && (
                                    <FieldTextInput
                                        id="reasonOfCancellation"
                                        name="reasonOfCancellation"
                                        className={css.generalInput}
                                        label={intl.formatMessage({
                                            id: 'CancelInquiryModal.reason',
                                        })}
                                        placeholder=""
                                        validate={validators.composeValidators(
                                            required
                                        )}
                                    />
                                )}
                                <Button
                                    className={css.nextButton}
                                    style={{
                                        marginTop: 24,
                                        backgroundColor: '#D10000',
                                    }}
                                    type="submit"
                                    disabled={submitDisabled}>
                                    {intl.formatMessage({
                                        id: 'CancelInquiryModal.button',
                                    })}
                                </Button>
                            </form>
                        );
                    }}
                />
            </Modal>
        </div>
    );
};

export default CancelInquiryModal;
