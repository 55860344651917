
import React, { useEffect } from 'react';
import defaultLocations from '../../default-location-searches';
import Geocoder from './../../components/LocationAutocompleteInput/GeocoderMapbox'



const WPSearchPage = props => {
    async function getGeocoder() {
        // Create the Geocoder as late as possible only when it is needed.
        const { location, activity } = props.params
        // const geocoder = new Geocoder();
        // const { predictions } = await geocoder.getPlacePredictions(location, true)
        let url = `/s${activity !== 'all' ? `?pub_activities_key=has_any:${activity}` : ''}`
        const selectedLocation = defaultLocations.find(e => e.id == location)

    
        console.log(selectedLocation)
        if (selectedLocation) {
            const { bounds, address } = selectedLocation?.predictionPlace
            // const { bounds } = await geocoder.getPlaceDetails(predictions[0])
            url = `/s?address=${encodeURI(address)}&bounds=${bounds.ne.lat}%2C${bounds.ne.lng}%2C${bounds.sw.lat}%2C${bounds.sw.lng}${activity !== 'all' ? `&pub_activities_key=has_any:${activity}` : ''}`
            console.log(url)
        }
        if (typeof window !== 'undefined') {
            window.location.href = url
        }
    }

    useEffect(() => {
        getGeocoder()
    }, [])

    return <div></div>
}

export default WPSearchPage