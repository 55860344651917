import React, { useEffect } from 'react';
import {
    InfoBox,
    TxAddonCard,
} from '../../../../components';
import { TxAddonForm } from '../../../../forms';
import { FormattedMessage } from '../../../../util/reactIntl';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import css from '../OfferModal.module.css';

const { Money } = sdkTypes;

const Addons = (props) => {
    const {
        addOns,
        intl,
        values,
        form,
        addonFormOpen,
        setAddonFormOpen,
    } = props;

    const onRemoveAddon = (uuid) => {
        const remainingAddons = values.addons.filter(addon => addon.uuid !== uuid);
        form.change("addons", remainingAddons);
    };

    const onEditAddon = (addon) => {
        const {
            description,
            price,
            title,
            typeOfCharge,
            qty,
            mwst,
            uuid,
        } = addon;

        setAddonFormOpen(uuid);
        form.batch(() => {
            form.change("addonTitle", title);
            form.change("addonDesc", description);
            form.change("addonTypeOfCharge", typeOfCharge);
            form.change("addonQty", qty);
            form.change("addonPrice", new Money(price.amount, price.currency));
            form.change("addonMwSt", mwst);
        });
    };

    useEffect(() => {
        if (!values.addons) {
            form.change("addons", addOns);
        };
    }, [addOns]);

    return <div>
        <h2 className={css.title}>
            <FormattedMessage id="OfferModal.titleAddons" />
        </h2>
        <h4 className={css.subTitle}>
            <FormattedMessage id="OfferModal.subTitleAddons" />
        </h4>

        <InfoBox
            title={<FormattedMessage id="OfferModal.addonsInfo" />}
        />

        {addonFormOpen ? <TxAddonForm
            intl={intl}
            form={form}
            values={values}
        /> : <>
            <h3 className={css.sectionTitle}>
                <FormattedMessage id="OfferModal.addonsT" />
            </h3>

            <div>
                {values.addons?.map(addon => {
                    return <TxAddonCard
                        key={addon.uuid}
                        intl={intl}
                        addon={addon}
                        onEditAddon={onEditAddon}
                        onRemoveAddon={onRemoveAddon}
                    />
                })}
            </div>

            <div
                className={css.createNewAddon}
                onClick={() => setAddonFormOpen('new')}
            >
                <FormattedMessage id="OfferModal.createNewAddon" />
            </div>
        </>}

    </div>
};

export default Addons;
