import React from 'react';
import PropTypes from 'prop-types';
import SVGLogo from './logo.svg';

const IconLogo = (props) => {
    const { className, format, ...rest } = props;

    return <img width="141" src={SVGLogo} alt="Robin Room Logo" />;
};

const { string } = PropTypes;

IconLogo.defaultProps = {
    className: null,
};

IconLogo.propTypes = {
    className: string,
};

export default IconLogo;
