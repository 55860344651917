/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,

} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';


import { DEFAULT_ADDONS } from '../../data/addOns';
import AddOnCard from '../../components/AddOnCard/AddOnCard';
import DeleteIcon from '../../components/DeleteIcon/DeleteIcon';
import EditIcon from '../../components/EditIcon/EditIcon';

import css from './EditListingClusterForm.module.css';

const AddOnCardList = (props) => {
    const {
        intl,
        addOns,
        prefixRenderComponent,
        showCreateNewAddOn,
        handleOnCreateNewAddOn,
        onEditAddOn,
        onDeleteAddOn,
        setTab
    } = props;

    /* check by default addons by provided addonTitle
return Boolean, */
    const isCustomAddOn = (addonTitle) =>
        !DEFAULT_ADDONS.find((a) => a.title === addonTitle);

    const addOnActions = (_addOn) => (
        <div className={css.addOnHeadingContainer}>
            <div className={css.customAddonLabel}>
                {isCustomAddOn(_addOn.title) ? 'Custom Add-on' : ''}
            </div>
            <div className={css.addOnActionsContainer}>
                <a
                    className={css.actionText}
                    onClick={() => {
                        window.localStorage.setItem('isEditAddon', 'yes');
                        onEditAddOn(_addOn);
                    }}>
                    <EditIcon />
                </a>

                <span className={css.addOnActionTextSeparator}></span>
                <a
                    className={classNames(css.actionTextDelete)}
                    onClick={() => onDeleteAddOn(_addOn)}>
                    <DeleteIcon />
                </a>
            </div>
        </div>
    );

    const customAddOnBtnLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.customAddOnBtnLabel',
    });

    return (
        <div>
            {React.Children.toArray(
                addOns.map((addOn, index) => {
                    return (
                        <AddOnCard
                            className={css.addOnCard}
                            intl={intl}
                            addOn={addOn}
                            prefixComponentRender={prefixRenderComponent}
                            headingComponentRender={addOnActions}
                            showDivider={index + 1 !== addOns.length}
                        />
                    );
                })
            )}
            {showCreateNewAddOn && (
                <a
                    className={css.customAddonBtn}
                    onClick={handleOnCreateNewAddOn}>
                    {customAddOnBtnLabel}
                </a>

            )}
            <Button className={css.returnButton} onClick={() => setTab('activity')}>
           <FormattedMessage id="EditListingAddOnForm.returntoActivityTypesBtnLabel"/>
            </Button>
        </div>

    );
};
AddOnCardList.propTypes = {};

export default compose(injectIntl)(AddOnCardList);
