import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = (props) => {
    const [mounted, setMounted] = useState(false);
    const { rootClassName, className } = props;

    useEffect(() => {
        setMounted(true);
    }, []);

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.heroContent}>
                <h1
                    className={classNames(css.heroMainTitle, {
                        [css.heroMainTitleFEDelay]: mounted,
                    })}
                >
                    <FormattedMessage id="SectionHero.title" />
                </h1>
                <h2
                    className={classNames(css.heroSubTitle, {
                        [css.heroSubTitleFEDelay]: mounted,
                    })}
                >
                    <FormattedMessage id="SectionHero.subTitle" />
                </h2>
                <NamedLink
                    name="SearchPage"
                    to={{
                        search: 'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
                    }}
                    className={classNames(css.heroButton, {
                        [css.heroButtonFEDelay]: mounted,
                    })}
                >
                    <FormattedMessage id="SectionHero.browseButton" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHero;
