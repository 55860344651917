import React, { useState, useEffect, useRef } from 'react';
import {
    FieldTextInput,
    FieldCheckbox,
    FieldSelect,
    FieldDateInput,
    SecondaryButton,
    InfoBox,
} from '../../../../components';
import {
    generateStartTimes,
    generateEndTimes,
} from '../../../../util/misc';
import { FormattedMessage } from '../../../../util/reactIntl';
import * as validators from '../../../../util/validators';
import {
    getDefaultTimeZoneOnBrowser,
    getMonthStartInTimeZone,
    nextMonthFn,
    prevMonthFn,
} from '../../../../util/dates';
import {
    onMonthClick,
    formatFieldDateInput,
    parseFieldDateInput,
    Next,
    Prev,
} from '../../../../forms/EditListingAvailabilityExceptionForm/EditListingAvailabilityExceptionForm';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';
import css from '../OfferModal.module.css';

const TODAY = new Date();
const DateFormattingOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
};

const defaultTimeZone = () => typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const Details = (props) => {
    const {
        intl,
        offerModalType,
        initialStep1Values,
        values,
        form,
        txId,
        providerId,
        onMonthChanged,
        sendFileInProgress,
        sendFileError,
        onUploadFile,
        tempFileId,
    } = props;

    const isDefaultOfferModalType = offerModalType == "default";
    const fileInput = useRef(null);

    const timeZone = defaultTimeZone();
    const startTimes = values.startDate && values.startDate.date ? generateStartTimes(values.startDate.date) : [];
    const endTimes = values.startTime ? generateEndTimes(
        values.startDate.date,
        values.endDate.date,
        Number(values.startTime.split(":")[0]),
    ) : [];

    const [currentMonth, setCurrentMonth] = useState(
        getMonthStartInTimeZone(TODAY, timeZone)
    );

    const [noFormChange, setNoFormChange] = useState(false);

    const required = validators.required('This field is required');
    const minZero = validators.minNo(
        'Number can not be below zero',
        0,
    );
    const minOne = validators.minNo(
        'Number can not be below 1',
        1,
    );

    const isDayBlocked = (day) => {
        const zeroHoursStartDate = new Date(new Date(values.startDate.date).toDateString());
        return day._d < zeroHoursStartDate;
    };

    const handleMonthClick = onMonthClick(
        currentMonth,
        setCurrentMonth,
        timeZone,
        onMonthChanged,
    );

    const handleFileChange = event => {
        setNoFormChange(true);
        const tempFile = event.target.files[0];
        const formData = new FormData();
        formData.append("file", tempFile);
        formData.append("file_type", "offer");
        formData.append('name', tempFile.name);
        formData.append('tx_id', txId.uuid);
        formData.append('user_id', providerId.uuid);
        onUploadFile(formData);
    };

    useEffect(() => {
        if (noFormChange) return;
        const {
            startDate,
            endDate,
            startTime,
            endTime,
            noOfGuests,
            spaceRentalPrice,
            taxAmount,
            disableSpaceRentalPrice,
            attachedFileId,
        } = initialStep1Values;

        if (!values.noOfGuests) {
            form.reset();
            form.batch(() => {
                form.change("startDate", startDate);
                form.change("endDate", endDate);
                form.change("startTime", startTime);
                form.change("endTime", endTime);
                form.change("noOfGuests", noOfGuests);

                if (spaceRentalPrice) {
                    form.change("spaceRentalPrice", spaceRentalPrice);
                };
                if (taxAmount) {
                    form.change("taxAmount", taxAmount);
                };
                if (disableSpaceRentalPrice) {
                    form.change("disableSpaceRentalPrice", disableSpaceRentalPrice);
                };
                if (attachedFileId) {
                    form.change("attachedFileId", attachedFileId);
                };
            });
        };
    }, [initialStep1Values]);

    useEffect(() => {
        if (tempFileId) {
            setNoFormChange(false);
            form.change("attachedFileId", tempFileId);
        };
    }, [tempFileId]);

    return <div>
        <h2 className={css.title}>
            <FormattedMessage id={isDefaultOfferModalType ? "OfferModal.titleDetails" : "OfferModal.titleDetailsFile"} />
        </h2>
        <h4 className={css.subTitle}>
            <FormattedMessage id={isDefaultOfferModalType ? "OfferModal.subTitleDetails" : "OfferModal.subTitleFile"} />
        </h4>

        <h3 className={css.sectionTitle}>
            <FormattedMessage id="OfferModal.dateTimeT" />
        </h3>

        <div className={css.datesWrapper}>
            <FieldDateInput
                className={css.fieldDateInput}
                name="startDate"
                id="startDate"
                label={intl.formatMessage({
                    id: 'OfferModal.startDate',
                })}
                placeholderText={intl.formatDate(
                    TODAY,
                    DateFormattingOptions,
                )}
                format={formatFieldDateInput(timeZone)}
                parse={parseFieldDateInput(timeZone)}
                isDayBlocked={() => { }}
                onChange={(value) => {
                    form.change("endDate", "");
                    form.change("startTime", "");
                    form.change("endTime", "");
                }}
                onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                navNext={<Next
                    currentMonth={currentMonth}
                    timeZone={timeZone}
                />}
                navPrev={<Prev
                    currentMonth={currentMonth}
                    timeZone={timeZone}
                />}
                useMobileMargins
                showErrorMessage={true}
                validate={validators.composeValidators(
                    validators.bookingDateRequired('Required'),
                    validators.validBookingDate("Date can't be in the past"),
                )}
            />

            <FieldDateInput
                className={css.fieldDateInput}
                name="endDate"
                id="endDate"
                label={intl.formatMessage({
                    id: 'OfferModal.endDate',
                })}
                placeholderText={intl.formatDate(
                    TODAY,
                    DateFormattingOptions,
                )}
                format={formatFieldDateInput(timeZone)}
                parse={parseFieldDateInput(timeZone)}
                isDayBlocked={isDayBlocked}
                onChange={(value) => {
                    form.change("startTime", "");
                    form.change("endTime", "");
                }}
                onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                navNext={<Next
                    currentMonth={currentMonth}
                    timeZone={timeZone}
                />}
                navPrev={<Prev
                    currentMonth={currentMonth}
                    timeZone={timeZone}
                />}
                useMobileMargins
                showErrorMessage={true}
                validate={validators.composeValidators(
                    validators.bookingDateRequired('Required'),
                    validators.validBookingDate("Date can't be in the past"),
                )}
            />
        </div>

        <div className={css.datesWrapper}>
            <div className={css.timeAndIconWrapper}>
                <FieldSelect
                    name="startTime"
                    id="startTime"
                    className={values.startDate
                        ? css.fieldSelect
                        : css.fieldSelectDisabled
                    }
                    selectClassName={values.startDate
                        ? css.select
                        : css.selectDisabled
                    }
                    label={intl.formatMessage({
                        id: 'OfferModal.startTime',
                    })}
                    disabled={!values.startDate}
                    onChange={(value) => {
                        form.change("endTime", "");
                    }}
                    validate={validators.required('Required')}
                >
                    <option value="" disabled>
                        choose time
                    </option>
                    {values.startDate ? startTimes.map((t) => (
                        <option
                            key={t}
                            value={t}
                        >
                            {t}
                        </option>
                    )) : <option value={null}>
                        12:00
                    </option>
                    }
                </FieldSelect>
            </div>
            <div className={css.timeAndIconWrapper}>
                <FieldSelect
                    name="endTime"
                    id="endTime"
                    className={values.startDate
                        ? css.fieldSelect
                        : css.fieldSelectDisabled
                    }
                    selectClassName={values.startDate
                        ? css.select
                        : css.selectDisabled
                    }
                    label={intl.formatMessage({
                        id: 'OfferModal.endTime',
                    })}
                    disabled={!values.endDate || !values.startTime}
                    onChange={() => { }}
                    validate={validators.required('Required')}
                >
                    <option value="" disabled>
                        choose time
                    </option>
                    {values.endDate && values.startTime ? endTimes.map((t) => (
                        <option
                            key={t}
                            value={t}
                        >
                            {t}
                        </option>
                    )) : <option value={null}>
                        12:00
                    </option>
                    }
                </FieldSelect>
            </div>
        </div>

        <h3 className={css.sectionTitle}>
            <FormattedMessage id="OfferModal.spaceT" />
        </h3>

        <FieldTextInput
            id="noOfGuests"
            name="noOfGuests"
            type="number"
            className={css.generalInput}
            label={intl.formatMessage({
                id: 'OfferModal.noOfGuests'
            })}
            placeholder=''
            validate={validators.composeValidators(
                required,
                minOne,
            )}
        />

        <FieldTextInput
            id="spaceRentalPrice"
            name="spaceRentalPrice"
            type="number"
            className={css.generalInput}
            label={intl.formatMessage({
                id: isDefaultOfferModalType ? 'OfferModal.spaceRentalPrice' : 'OfferModal.totalPrice'
            })}
            placeholder=''
            validate={validators.composeValidators(
                required,
                minZero,
            )}
        />

        {!isDefaultOfferModalType && <FieldTextInput
            id="taxAmount"
            name="taxAmount"
            type="number"
            className={css.generalInput}
            label={intl.formatMessage({
                id: 'OfferModal.taxAmount'
            })}
            placeholder=''
            validate={validators.composeValidators(
                required,
                minZero,
            )}
        />}

        {isDefaultOfferModalType ? <>
            <FieldCheckbox
                id='disableSpaceRentalPrice'
                name='disableSpaceRentalPrice'
                className={css.customCheckbox}
                label={intl.formatMessage({
                    id: 'OfferModal.disableSpaceRentalPrice'
                })}
            />
            <div className={css.smallDesc}>
                <FormattedMessage id="OfferModal.disableSpaceRentalPriceDesc" />
            </div>
        </> : null}

        <input
            id="file"
            name="file"
            type="file"
            accept="application/pdf"
            hidden
            onChange={(e) => handleFileChange(e)}
            ref={fileInput}
        />

        {!isDefaultOfferModalType && <SecondaryButton
            type="button"
            className={css.uploadPDF}
            inProgress={sendFileInProgress}
            ready={values.attachedFileId}
            disabled={sendFileInProgress || sendFileError}
            onClick={() => fileInput.current.click()}
        >
            <UploadIcon />
            <FormattedMessage id="OfferModal.uploadPDF" />
        </SecondaryButton>}
        {sendFileError && <p style={{ color: 'red' }}>{sendFileError}</p>}

        {!isDefaultOfferModalType && <div className={css.fileOfferInfo}>
            <InfoBox
                title={<strong><FormattedMessage id="OfferModal.fileOfferInfoTitle" /></strong>}
                content={<FormattedMessage id="OfferModal.fileOfferInfoText" />}
            />
        </div>}
    </div>
};

export default Details;
