import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BoltIcon from '../../assets/icons/bolt-icon.svg';

const IconBolt = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={BoltIcon} alt="Bolt/Lighning icon" />;
};

const { string } = PropTypes;

IconBolt.defaultProps = {
    className: null,
    rootClassName: null,
};

IconBolt.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconBolt;
