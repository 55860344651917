import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import ClusterCard from '../../components/ClusterCard/ClusterCard';

const ClusterCardList = (props) => {
    const {
        clusters = [],
        addOns = [],
        actions,
        intl,
        onManageDisableScrolling,
    } = props;

    return React.Children.toArray(
        clusters.map((_clus) => (
            <ClusterCard
                cluster={_clus}
                addOns={addOns}
                intl={intl}
                headingComponentRender={actions}
                onManageDisableScrolling={onManageDisableScrolling}
            />
        ))
    );
};

ClusterCardList.propTypes = {};

export default compose(injectIntl)(ClusterCardList);
