/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { func, array, string } from 'prop-types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { Button } from '../../components';
import { CLUSTER_SECTION } from './ClusterForm';
import { CLUSTER_TABS } from './EditListingClusterForm';
import AddOnsTab from './AddOnsTab';
import ClusterTab from './ClusterTab';

import css from './EditListingClusterForm.module.css';

const ClusterFormTabs = (props) => {
    const {
        tab = CLUSTER_TABS.CLUSTER,
        setTab,
        clusters,
        addOns,
        draftCluster,
        fetchErrors,
        saveActionMsg,
        onSubmit
    } = props;

    const [clusterState, setClusterState] = useState(
        draftCluster
            ? clusters.find((_c) => _c.uuid === draftCluster?.uuid)
            : null
    );

    const [editClusterId, setEditClusterId] = useState(null)

    const [addOnState, setAddOnState] = useState();
    const [currentAddonCluster, setCurrentAddonCluster] = useState();

    const [openClusterSection, setOpenClusterSection] = useState(
        // bring back draft activity to bring back to the add-ons section
        draftCluster ? draftCluster.nextSection : CLUSTER_SECTION.DETAILS
    );
    const isActiveTab = (tabTitle) => tabTitle === tab;

    const handleOnCreateNewAddOn = () => {
        setTab(CLUSTER_TABS.ADD_ON);
        setAddOnState({
            uuid: uuid(),
            isClusterDraft: clusterState?.uuid,
            isNewAddOn: true,
        });
        setCurrentAddonCluster(clusterState?.uuid);
    };

    const handleOnCancelAddOnForm = () => {
        setAddOnState();
        if (addOnState?.isClusterDraft) {
            setTab(CLUSTER_TABS.CLUSTER);
            setOpenClusterSection(CLUSTER_SECTION.ADD_ON);
            setCurrentAddonCluster(null);
        }
    };

    const handleOnClickManageAddOn = () => setTab(CLUSTER_TABS.ADD_ON);

    const handleOnCancelClusterForm = () => {
        setClusterState(null);
        setEditClusterId(null)
        setOpenClusterSection(CLUSTER_SECTION.DETAILS);
    };

    useEffect(() => {
        if (clusters.length > 0) {
            setClusterState(draftCluster
                ? clusters.find((_c) => _c.uuid === draftCluster?.uuid)
                : editClusterId ? clusters.find((_c) => _c.uuid === editClusterId) : null)
        }
    }, [clusters, editClusterId])

    const renderTabBody = () => <>
        <div className={tab === CLUSTER_TABS.CLUSTER
            ? css.showTab
            : css.hideTab}>
            <ClusterTab
                clusters={clusters}
                addOns={addOns}
                clusterState={clusterState}
                setClusterState={setClusterState}
                handleOnCreatNewAddOn={handleOnCreateNewAddOn}
                openClusterSection={openClusterSection}
                setOpenClusterSection={setOpenClusterSection}
                handleOnCancelClusterForm={handleOnCancelClusterForm}
                handleOnClickManageAddOn={handleOnClickManageAddOn}
                setEditClusterId={setEditClusterId}
                {...props}
            />
        </div>
        <div className={tab === CLUSTER_TABS.ADD_ON
            ? css.showTab
            : css.hideTab}>
            <AddOnsTab
                {...props}
                addOns={addOns}
                addOnState={addOnState}
                setAddOnState={setAddOnState}
                handleOnCancelAddOnForm={handleOnCancelAddOnForm}
                currentAddonCluster={currentAddonCluster}
                setTab={setTab}
            />
        </div>
    </>;
    const { updateListingError, showListingsError } = fetchErrors || {};
    const { ready, updateInProgress } = props;

    return (
        <div>
            {updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingAddOnForm.updateFailed" />
                </p>
            ) : null}
            {showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingAddOnForm.showListingFailed" />
                </p>
            ) : null}
            <div className={css.activityTabNavContainer}>
                {React.Children.toArray(
                    Object.values(CLUSTER_TABS).map((_tab) => (
                        <div className={css.clusterTab}>
                            <a
                                className={classNames(
                                    css.activityTabLink,
                                    isActiveTab(_tab) ? css.selectedLink : ''
                                )}
                                onClick={() => {
                                    setTab(_tab);
                                }}>
                                <FormattedMessage
                                    id={`EditListingAddOnForm.clusterTabTitle.${_tab}`}
                                />
                            </a>
                        </div>
                    ))
                )}
            </div>
            {renderTabBody()}
            {!clusterState && !addOnState && tab == CLUSTER_TABS.CLUSTER && (
                <Button
                    inProgress={updateInProgress}
                    ready={ready}
                    type="button"
                    className={classNames(
                        css.submitButton,
                        css.subFieldsSubmitBtn,
                        css.activityDetailsNextBtn
                    )}
                    disabled={!(clusters.length > 0)}
                    onClick={() => props.onSubmit({ moveToNextTab: true })}>
                    {saveActionMsg}
                </Button>
            )}
        </div>
    );
};

ClusterFormTabs.defaultProps = {
    clusters: [],
    addOns: [],
};

ClusterFormTabs.propTypes = {
    tab: string,
    setTab: func.isRequired,
    clusters: array,
};

export default compose(injectIntl)(ClusterFormTabs);
