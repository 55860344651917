import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, RedirectingListingCard, PaginationLinks } from '../../components';
import TestPhoto from '../../assets/cardPhoto.png'
import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = (props) => {
    const {
        className,
        rootClassName,
        cardsClassName,
        cardClassName,
        listings,
        pagination,
        search,
        setActiveListing,
    } = props;
    const classes = classNames(rootClassName || css.root, className);

    const paginationLinks =
        pagination && pagination.totalPages > 1 ? (
            <PaginationLinks
                className={css.pagination}
                pageName="SearchPage"
                pageSearchParams={search}
                pagination={pagination}
            />
        ) : null;

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
    ].join(', ');

    // window.dataLayer.push({
    //     event: 'view_search_results',
    // });

    // console.log('onActivateListing', listings)

    return (
        <div className={classes}>
            <div className={classNames(css.listingCards, cardsClassName || '')}>
                {listings.map((l) => (
                    <ListingCard
                        className={classNames(cardClassName || css.listingCard)}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={cardRenderSizes}
                        setActiveListing={setActiveListing}
                    />
                ))}
                <RedirectingListingCard
                    className={classNames(cardClassName || css.listingCard)}
                    image={TestPhoto}
                />
                {props.children}
                {/* <div>
                <img src={TestPhoto} className={css.listingPhoto}/>
                </div> */}
                
            </div>
            {paginationLinks}
        </div>
    );
};

SearchResultsPanel.defaultProps = {
    children: null,
    className: null,
    listings: [],
    pagination: null,
    rootClassName: null,
    search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
    children: node,
    className: string,
    listings: array,
    pagination: propTypes.pagination,
    rootClassName: string,
    search: object,
};

export default SearchResultsPanel;
