export const DEFAULT_ADDONS = [
    { title: 'Drinks', description: 'A minimum consumption of drinks from the bar applies to all kinds of events. Details can be defined after the booking.' },
    { title: 'Food', description: 'A minimum consumption of food applies to all kinds of events. Details can be defined after the booking.' },
    { title: 'Cleaning Service', description: '' },
    { title: 'Parking space', description: '' },
    { title: 'Staff', description: '' },
];
export const DEFAULT_ADDONS_TITLES = DEFAULT_ADDONS.map((obj) => obj.title);

/* check by default addons by provided addonTitle
return Boolean, */
export const isCustomAddOn = (addonTitle) =>
    !DEFAULT_ADDONS.find((a) => a.title === addonTitle);

// get Addon's description by provided addOn
export const getAddOnDefaultDescription = (addOn) =>
    addOn?.description ||
    DEFAULT_ADDONS.find((a) => a.title === addOn.title)?.description;

// get Addon by provided title from the provided list
export const getAddOn = (addOnTitle, _list) => {
    return _list.find((a) => a.title === addOnTitle);
};

export const getAddOnTitleByUUID = (_uuid, _list) => {
    return _list.find((a) => a.uuid === _uuid)?.title;
};
