import React from 'react';
import {
    FieldSelect,
    FieldTextInput,
    FieldCurrencyInput,
} from '../../components';
import * as validators from '../../util/validators';
import config from '../../config';
import {
    getIntlTypeOfChargeOptionLabel,
    TYPE_OF_CHARGE_OPTIONS,
} from '../../data/typeOfCharges';
import css from './TxAddonForm.module.css';

const TxAddonForm = (props) => {
    const {
        intl,
        form,
        values,
    } = props;

    const requiredFieldError = intl.formatMessage({
        id: 'EditListingAddOnForm.description',
    });
    const requiredFieldValidator = validators.required(requiredFieldError);
    const minZero = validators.minNo(
        'Number can not be below zero',
        0,
    );

    const titleInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.title',
    });
    const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.titleInputPlaceholder',
    });

    const descriptionInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.description',
    });
    const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.descriptionInputPlaceholder',
    });

    const typeOfChargeInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfCharge',
    });
    const typeOfChargePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfChargeInputPlaceholder',
    });

    const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.priceInputPlaceholder',
    });

    const typesWithPiece = ['perPiece', 'perPiecePerHour'];
    const showQtyInput = values.addonTypeOfCharge && typesWithPiece.includes(values.addonTypeOfCharge);

    return <div className={css.root}>
        <FieldTextInput
            id="addonTitle"
            name="addonTitle"
            type="text"
            className={css.generalInput}
            label={titleInputLabel}
            placeholder={titlePlaceholderMessage}
            validate={requiredFieldValidator}
        // disabled={isDefaultAddOn}
        />

        <FieldTextInput
            id="addonDesc"
            name="addonDesc"
            type="text"
            className={css.generalInput}
            label={descriptionInputLabel}
            placeholder={descriptionPlaceholderMessage}
            validate={requiredFieldValidator}
        />

        <FieldSelect
            id="addonTypeOfCharge"
            name="addonTypeOfCharge"
            className={css.generalInput}
            label={typeOfChargeInputLabel}
            validate={requiredFieldValidator}
        >
            <option disabled value="">
                {typeOfChargePlaceholderMessage}
            </option>
            {TYPE_OF_CHARGE_OPTIONS.map((type) => (
                <option key={type} value={type}>
                    {getIntlTypeOfChargeOptionLabel(type, intl)}
                </option>
            ))}
        </FieldSelect>

        {showQtyInput && <FieldTextInput
            id="addonQty"
            name="addonQty"
            type="number"
            className={css.generalInput}
            label="Number of pieces"
            placeholder="3"
            validate={validators.composeValidators(
                requiredFieldValidator,
                minZero,
            )}
        />}

        <FieldCurrencyInput
            id="addonPrice"
            name="addonPrice"
            className={css.generalInput}
            label="Price (CHF)"
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={validators.required(
                intl.formatMessage({
                    id: 'EditListingAddOnForm.priceRequired'
                })
            )}
        />

        {/* <FieldTextInput
            id="addonMwSt"
            name="addonMwSt"
            type="number"
            className={css.generalInput}
            label="MwSt (%)"
            placeholder="8.1"
            validate={validators.composeValidators(
                requiredFieldValidator,
                minZero,
            )}
        /> */}
    </div>
};

export default TxAddonForm;
