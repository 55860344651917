import React from 'react';
import PropTypes from 'prop-types';

import { FieldCheckboxGroup } from '../../components';
import { GET_ACTIVITY_LIST } from '../../data/clusters';
import { CLUSTER_SECTION } from './ClusterForm';

const ClusterActivityForm = (props) => {
    const {
        renderClusterFormActionBtn,
        submitLabelId,
        disabledNextBtn,
        values,
    } = props;


   
    return (
        <div>
            <FieldCheckboxGroup
                twoColumns
                id="activities_key"
                name="activities_key"
                options={GET_ACTIVITY_LIST().filter(
                    (_a) => _a.cluster.key === values?.title
                )}
            />

            {renderClusterFormActionBtn(
                disabledNextBtn,
                CLUSTER_SECTION.ADD_ON,
                submitLabelId
            )}
        </div>
    );
};

ClusterActivityForm.propTypes = {};

export default ClusterActivityForm;
