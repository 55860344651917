import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
    Form,
    PrimaryButton,
    FieldTextInput,
    FieldBirthdayInput,
    FieldPhoneNumberInput,
    FieldCheckbox
} from '../../components';

import css from './SignupForm.module.css';
// import { FieldCheckbox } from '../../examples';
// import { format } from '../PayoutDetailsForm/normalizePhoneNumberUS';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = (props) => (
    <FinalForm
        {...props}
        render={(fieldRenderProps) => {
            const {
                rootClassName,
                className,
                formId,
                handleSubmit,
                inProgress,
                invalid,
                intl,
                onOpenTermsOfService,
            } = fieldRenderProps;

            // email
            const emailLabel = intl.formatMessage({
                id: 'SignupForm.emailLabel',
            });
            const emailPlaceholder = intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
            });
            const emailRequiredMessage = intl.formatMessage({
                id: 'SignupForm.emailRequired',
            });
            const emailRequired = validators.required(emailRequiredMessage);
            const emailInvalidMessage = intl.formatMessage({
                id: 'SignupForm.emailInvalid',
            });
            const emailValid = validators.emailFormatValid(emailInvalidMessage);

            // password
            const passwordLabel = intl.formatMessage({
                id: 'SignupForm.passwordLabel',
            });
            const passwordPlaceholder = intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
            });
            const passwordRequiredMessage = intl.formatMessage({
                id: 'SignupForm.passwordRequired',
            });
            const passwordMinLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooShort',
                },
                {
                    minLength: validators.PASSWORD_MIN_LENGTH,
                }
            );
            const passwordMaxLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooLong',
                },
                {
                    maxLength: validators.PASSWORD_MAX_LENGTH,
                }
            );
            const passwordMinLength = validators.minLength(
                passwordMinLengthMessage,
                validators.PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
                passwordMaxLengthMessage,
                validators.PASSWORD_MAX_LENGTH
            );
            const passwordRequired = validators.requiredStringNoTrim(
                passwordRequiredMessage
            );
            const passwordValidators = validators.composeValidators(
                passwordRequired,
                passwordMinLength,
                passwordMaxLength
            );

            // firstName
            const firstNameLabel = intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
            });
            const firstNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
            });
            const firstNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
            });
            const firstNameRequired = validators.required(
                firstNameRequiredMessage
            );

            // lastName
            const lastNameLabel = intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
            });
            const lastNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
            });
            const lastNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
            });
            const lastNameRequired = validators.required(
                lastNameRequiredMessage
            );

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;

            const handleTermsKeyUp = (e) => {
                // Allow click action with keyboard like with normal links
                if (e.keyCode === KEY_CODE_ENTER) {
                    onOpenTermsOfService();
                }
            };
            const termsLink = (
                <span className={css.termsLink} role="button" tabIndex="0">
                    <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                </span>
            );

            //Birth Date Validation
            const identity = (v) => v;
            const BirthDayRequired = validators.required(
                'A valid date is required'
            );
            const minAge = 18;
            const minAgeRequired = validators.ageAtLeast(
                 `Age should be at least ${minAge}`,

                minAge
             );
            // const minAgeRequired = validators.required(
            //     `Age should be at least 18`
                
            
            // );

            //Phone Number Validation
            const phoneRequiredlabel = intl.formatMessage({
                id: 'ConfirmSignupForm.phoneRequiredlabel',
            });

            const PhoneRequired = validators.required(phoneRequiredlabel);

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div>
                        <div className={css.name}>
                            <FieldTextInput
                                className={css.firstNameRoot}
                                type="text"
                                id={formId ? `${formId}.fname` : 'fname'}
                                name="fname"
                                autoComplete="given-name"
                                label={firstNameLabel}
                                placeholder={firstNamePlaceholder}
                                validate={firstNameRequired}
                            />
                            <FieldTextInput
                                className={css.lastNameRoot}
                                type="text"
                                id={formId ? `${formId}.lname` : 'lname'}
                                name="lname"
                                autoComplete="family-name"
                                label={lastNameLabel}
                                placeholder={lastNamePlaceholder}
                                validate={lastNameRequired}
                            />
                        </div>
                        <FieldTextInput
                            type="email"
                            className={css.email}
                            id={formId ? `${formId}.email` : 'email'}
                            name="email"
                            autoComplete="email"
                            label={emailLabel}
                            placeholder={emailPlaceholder}
                            validate={validators.composeValidators(
                                emailRequired,
                                emailValid
                            )}
                        />
                        <FieldTextInput
                            className={css.password}
                            type="password"
                            id={formId ? `${formId}.password` : 'password'}
                            name="password"
                            autoComplete="new-password"
                            label={passwordLabel}
                            placeholder={passwordPlaceholder}
                            validate={passwordValidators}
                        />
                        {/* <FieldPhoneNumberInput
                            className={css.phoneNum}
                            id={`${formId}.phoneNumber`}
                            name="phoneNumber"
                            label="Phone number"
                            placeholder="+4100223445"
                            validate={PhoneRequired}
                        /> */}
                        {/* <FieldBirthdayInput
                            className={css.birthDay}
                            id={`birthday`}
                            name="birthday"
                            type="date"
                            label="Enter your birth date"
                            format={identity}
                            validate={validators.composeValidators(
                                BirthDayRequired,
                                minAgeRequired
                            )}
                        /> */}
                        {/* <div style={{marginTop:"39px", marginBottom:"0px"}}>
                          <FieldCheckbox
                            className={css.birthDay}
                            style={{width:"15px"}}
                            id={`birthday`}
                            name="birthday"
                            value="birthday"
                            label="I certify that i am at least 18 years old"
                            format={identity}
                            redStar={true}
                            validate={minAgeRequired} //use disabled
                            required
                        />
                        </div> */}
                    </div>
                    <div className={css.bottomWrapper}>
                        <p className={css.bottomWrapperText}>
                            <span className={css.termsText}>
                                <FormattedMessage id="SignupForm.termsAndConditionsAcceptText" />
                                
                                <a
                                    href="https://robin-room.com/terms-and-conditions/"
                                    target="_blank">
                                    {termsLink}
                                </a>
                            </span>
                        </p>
                        <PrimaryButton
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled}>
                            <FormattedMessage id="SignupForm.signUp" />
                        </PrimaryButton>
                    </div>
                </Form>
            );
        }}
    />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
    inProgress: bool,

    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
