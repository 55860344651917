export const CUSTERS = [
    {
        key: 'Celebration',
        label: 'Celebration',
        options: [
            {
                key: 'Birthday Party',
                label: 'Birthday Party',
            },
            {
                key: 'Company Party',
                label: 'Company Party',
            },
            {
                key: 'Graduation',
                label: 'Graduation',
            },
            {
                key: 'Babyshower',
                label: 'Babyshower',
            },
            {
                key: 'Family Celebration',
                label: 'Family Celebration',
            },
            {
                key: 'Party',
                label: 'Party',
            },
            {
                key: 'Bachelor Party',
                label: 'Bachelor Party',
            },
            {
                key: 'Bachelorette Party',
                label: 'Bachelorette Party',
            },
            {
                key: 'Wedding',
                label: 'Wedding',
            },
            {
                key: 'Wedding Reception',
                label: 'Wedding Reception',
            },
            {
                key: 'Marriage Proposal',
                label: 'Marriage Proposal',
            },
            {
                key: 'Apero',
                label: 'Apero',
            },
            {
                key: 'Kids Party',
                label: 'Kids Party',
            },
            {
                key: 'Reunion',
                label: 'Reunion',
            },
        ],
    },
    {
        key: 'Classes & Performance',
        label: 'Classes & Performance',
        options: [
            {
                key: 'Yoga',
                label: 'Yoga',
            },
            {
                key: 'Mediation',
                label: 'Mediation',
            },
            {
                key: 'Coaching',
                label: 'Coaching',
            },
            {
                key: 'Tastings',
                label: 'Tastings',
            },
            {
                key: 'Cooking Classes',
                label: 'Cooking Classes',
            },
            {
                key: 'Wellness',
                label: 'Wellness',
            },
            {
                key: 'Theater',
                label: 'Theater',
            },
            {
                key: 'Casting',
                label: 'Casting',
            },
            {
                key: 'Stand Up Comedy',
                label: 'Stand Up Comedy',
            },
            {
                key: 'Concert',
                label: 'Concert',
            },
            {
                key: 'Fashion Show',
                label: 'Fashion Show',
            },
            {
                key: 'Art Show',
                label: 'Art Show',
            },
            {
                key: 'Hackathon',
                label: 'Hackathon',
            },
        ],
    },
    {
        key: 'Business',
        label: 'Business',
        options: [
            {
                key: 'Working/ Co-Working',
                label: 'Working/ Co-Working',
            },
            {
                key: 'Workshop',
                label: 'Workshop',
            },
            {
                key: 'Off-Site',
                label: 'Off-Site',
            },
            {
                key: 'Team building event',
                label: 'Team building event',
            },
            {
                key: 'Company Party',
                label: 'Company Party',
            },
            {
                key: 'Christmas Dinner',
                label: 'Christmas Dinner',
            },
            {
                key: 'Presentation',
                label: 'Presentation',
            },
            {
                key: 'Product Launch',
                label: 'Product Launch',
            },
            {
                key: 'PR Event',
                label: 'PR Event',
            },
            {
                key: 'Meeting',
                label: 'Meeting',
            },
            {
                key: 'Networking',
                label: 'Networking',
            },
            {
                key: 'Seminar',
                label: 'Seminar',
            },
        ],
    },
    {
        key: 'Content Creation',
        label: 'Content Creation',
        options: [
            {
                key: 'Non-professional photo shooting',
                label: 'Non-professional photo/film shooting',
            },
            {
                key: 'Professional photo/ film shooting (productions)',
                label: 'Professional photo/ film shooting (productions)',
            },
            {
                key: 'Live-Stream',
                label: 'Live-Stream',
            },
            {
                key: 'Podcast Recording',
                label: 'Podcast Recording',
            },
            {
                key: 'Audio Recording',
                label: 'Audio Recording',
            },
            {
                key: 'Music Recording',
                label: 'Music Recording',
            },
        ],
    },
    {
        key: 'Sports',
        label: 'Sports',
        options: [
            {
                key: 'Fitness',
                label: 'Fitness',
            },
            {
                key: 'CrossFit',
                label: 'CrossFit',
            },
            {
                key: 'Personal Training',
                label: 'Personal Training',
            },
            {
                key: 'Dancing',
                label: 'Dancing',
            },
        ],
    },
    {
        key: 'Gathering',
        label: 'Gathering',
        options: [
            {
                key: 'Friends Dinner',
                label: 'Friends Dinner',
            },
            {
                key: 'Family Dinner',
                label: 'Family Dinner',
            },
            {
                key: 'Reunion',
                label: 'Reunion',
            },
        ],
    },
];

export const GET_ACTIVITY_LIST = () => {
    const _actList = [];
    CUSTERS.forEach(({ key, label, options }) => {
        const mappedActWithCuster = options.map((_a) => ({
            ..._a,
            cluster: { key, label },
        }));

        _actList.push([...mappedActWithCuster]);
    });
    return _actList.flat();
};
