/* eslint-disable jsx-a11y/anchor-is-valid */
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import React, { useState } from 'react';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import ClusterFormTabs from './ClusterFormTabs';
import config from '../../config';

export const CLUSTER_TABS = {
    CLUSTER: 'activity',
    ADD_ON: 'addOns',
};

export const EditListingClusterFormComponent = (props) => {
    // draftCluster determine which activity was on draft
    const draftCluster = props.initialValues?.draftCluster;
    const draftClusterDetails =
        draftCluster &&
        props.initialValues?.clusters?.find(
            (_clus) => _clus.uuid === draftCluster?.uuid
        );

    if (draftClusterDetails) {
        props.initialValues.cluster = draftClusterDetails;
    }

    const [openClusterTab, setOpenClusterTab] = useState(CLUSTER_TABS.CLUSTER);

    const { filterConfig, initialValues, onManageDisableScrolling } = props;
    const { clusters = [], addOns = [] } = initialValues;

    const handleOnSubmit = (values) => {
        const updatedValues = { ...initialValues, ...values };
        props.onSubmit(updatedValues);
        // This function redirects the cluster after the add on have been created. 
        // if (values?.addOn?.isNewAddOn) { 
        //     setTimeout(setOpenClusterTab(CLUSTER_TABS.CLUSTER), 2000);
        // }
    };
    const {
        saveActionMsg,
        disabled,
        ready,
        updated,
        updateInProgress,
        fetchErrors,
    } = props;

    const formProps = {
        saveActionMsg,
        disabled,
        ready,
        updated,
        updateInProgress,
        fetchErrors,
    };
    
    return (
        <ClusterFormTabs
            {...formProps}
            tab={openClusterTab}
            setTab={setOpenClusterTab}
            draftCluster={draftCluster}
            clusters={clusters}
            addOns={addOns}
            filterConfig={filterConfig}
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmit={handleOnSubmit}
        />
    );
};

EditListingClusterFormComponent.defaultProps = {
    fetchErrors: null,
    filterConfig: config.custom.filters,
};

EditListingClusterFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingClusterFormComponent);
