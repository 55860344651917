import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClockIcon from '../../assets/icons/clock-icon.svg';

const IconTime = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={ClockIcon} alt="clock icon" />;
};

const { string } = PropTypes;

IconTime.defaultProps = {
    className: null,
    rootClassName: null,
};

IconTime.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconTime;
