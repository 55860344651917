import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney } from '../../util/currency';
import { getIntlTypeOfChargeOptionLabel } from '../../data/typeOfCharges';
import { types as sdkTypes } from '../../util/sdkLoader';
import Markdown from 'markdown-to-jsx';

import css from './AddOnCard.module.css';
import { useState } from 'react';

const { Money } = sdkTypes;

const AddOnCard = (props) => {
    const {
        className,
        addOn,
        intl,
        headingComponentRender,
        headingContainerClassName,
        headingContainerRootClassName,
        prefixComponentRender,
        showDivider,
        secondaryColor,
    } = props;
    const { title, price, description, typeOfCharge } = addOn;
    const [expandDescription, setExpandDescription] = useState(false)

    const priceValue = (
        <>
            <span className={css.addOnPriceText}>{`+ ${formatMoney(
                intl,
                new Money(price.amount, price.currency),
                1.05
            )}`}</span>
            <span className={css.perHourLabel}>
                {getIntlTypeOfChargeOptionLabel(typeOfCharge, intl)}
            </span>
        </>
    );

    return (
        <div
            className={classNames(
                css.addOnContainer,
                showDivider ? css.addOnContainerDivider : '',
                className
            )}>
            {prefixComponentRender && prefixComponentRender(addOn)}
            <div>
                <div
                    className={classNames(
                        headingContainerRootClassName ||
                        css.headingComponentRoot,
                        headingContainerClassName
                    )}>
                    {headingComponentRender && headingComponentRender(addOn)}
                </div>
                <div className={css.addOnDetailsContainer}>
                    <div className={css.addOnInfos}>
                        <h3
                            className={classNames(
                                css.addOnTitle,
                                secondaryColor ? css.secondaryColor : ''
                            )}>
                            {title}
                        </h3>
                        <div className={css.addOnPriceMobileContainer}>
                            {priceValue}
                        </div>
                        {description && <p><Markdown>{description?.length > 150 && !expandDescription ? `${description.substring(0, 150)}...` : description}</Markdown></p>}
                        {description?.length > 150 && <a style={{ fontSize: '12px' }}
                            onClick={() => setExpandDescription(!expandDescription)}
                        >{expandDescription ? "See Less" : "See more"}</a>}
                    </div>
                    <div className={css.addOnPriceDesktopContainer}>
                        {priceValue}
                    </div>
                </div>
            </div>
        </div>
    );
};

AddOnCard.propTypes = {};

export default AddOnCard;
