import React, { useEffect, useState } from 'react';
import * as validators from '../../../../util/validators';
import { transactionLineItems } from '../../../../util/api';
import { attachTimeToDate } from '../../../../util/misc';
import { InfoBox, FieldCheckbox, FieldTextInput, IconSpinner } from '../../../../components';
import EstimatedBreakdownMaybe from '../../../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import { FormattedMessage } from '../../../../util/reactIntl';
import config from '../../../../config';
import { estimatedTotalPrice } from '../../../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import css from '../OfferModal.module.css';

const Review = (props) => {
    const {
        intl,
        form,
        timeZone,
        values,
        listingId,
        customerName,
        activity,
        initialStep3Values,
    } = props;

    const [loading, setLoading] = useState(false);
    const [lineItems, setLineItems] = useState([]);

    const required = validators.required('This field is required');
    const notFalse = validators.valueNotFalse('This field is required');

    const startDate = attachTimeToDate(new Date(values.startDate.date), values.startTime);
    const endDate = attachTimeToDate(new Date(values.endDate.date), values.endTime);
    const unitType = config.bookingUnitType;
    const bookingData = startDate && endDate ? {
        unitType,
        startDate,
        endDate,
        timeZone,
    } : null;

    const cancellationPolicyInputLabel = <div>
        <span>
            {intl.formatMessage({
                id: 'EditListingAddOnForm.activityCancellationPolicyInputLabel',
            })} <a
                href='https://robin-room.com/cancellation-templates/'
                target="_blank"
            >
                <span className={css.cancellationWrapper}>
                    ⓘ <div className={css.cancellationToolTip}>
                        {intl.formatMessage({
                            id: 'EditListingAddOnForm.activityCancellationPolicyToolTip',
                        })}
                    </div>
                </span>
            </a>
        </span>

    </div>;

    useEffect(() => {
        const {
            cancellationPolicy,
            agreeTnC,
        } = initialStep3Values;

        form.batch(() => {
            if (cancellationPolicy) {
                form.change("cancellationPolicy", cancellationPolicy);
            };
            if (agreeTnC) {
                form.change("agreeTnC", agreeTnC);
            };
        });
    }, [initialStep3Values]);

    useEffect(() => {
        async function fetchMyAPI() {
            setLoading(true);
            let response = await transactionLineItems({
                bookingData: {
                    startDate,
                    endDate,
                    noOfGuest: values.noOfGuests,
                    addOns: values.addons,
                    activity: activity,
                    removeFee: values.disableSpaceRentalPrice,
                    allAddons: values.addons,
                    offerPrice: Number(values.spaceRentalPrice) * 100,
                },
                listingId,
                isOwnListing: false,
            });
            setLineItems(response.data);

            const customerLineItems = response.data.filter((item) =>
                item.includeFor.includes('customer')
            );
            const payinTotal = estimatedTotalPrice(customerLineItems);
            form.change('payinTotal', payinTotal.amount);
            setLoading(false);
        };

        fetchMyAPI();
    }, []);

    return <div>
        <h2 className={css.title}>
            <FormattedMessage id="OfferModal.titleReview" />
        </h2>
        <h4 className={css.subTitle}>
            <FormattedMessage id="OfferModal.subTitleReview" values={{ customerName }} />
        </h4>

        <div className={css.breakdownContainer}>
            {loading ? <IconSpinner /> :
                lineItems && lineItems.length > 0 ? <EstimatedBreakdownMaybe
                    bookingData={bookingData}
                    lineItems={lineItems}
                    removeFee={values.disableSpaceRentalPrice}
                    userRole="provider"
                /> : null}
        </div>

        <FieldTextInput
            type="textarea"
            id="cancellationPolicy"
            name="cancellationPolicy"
            className={css.generalInput}
            label={cancellationPolicyInputLabel}
            placeholder="Cancellation Policy"
            validate={required}
            subLabel={<a
                href='https://robin-room.com/cancellation-templates/'
                target="_blank"
            >
                <p style={{ fontSize: "14px", marginTop: 0, marginBottom: 0 }}>
                    Cancellation Policy Examples
                </p>
            </a>}
        />

        <FieldCheckbox
            id="agreeTnC"
            name="agreeTnC"
            label={intl.formatMessage({
                id: "OfferModal.agreeTnC",
            }, {
                tcLink: <a href="https://robin-room.com/terms-and-conditions/" target="_blank">
                    terms and conditions
                </a>,
            })}
            className={css.tncCheckbox}
            validate={validators.composeValidators(
                required,
                notFalse,
            )}
        />

        <InfoBox
            title={<strong><FormattedMessage id="OfferModal.reviewInfoTitle" /></strong>}
            content={<ul className={css.reviewInfoList}>
                <li><FormattedMessage id="OfferModal.reviewInfoLine1" /></li>
                <li><FormattedMessage id="OfferModal.reviewInfoLine2" /></li>
                <li><FormattedMessage id="OfferModal.reviewInfoLine3" /></li>
                <li><FormattedMessage id="OfferModal.reviewInfoLine4" /></li>
            </ul>}
        />
    </div>
};

export default Review;
