import React from 'react';
import PropTypes from 'prop-types';
import AddOnCard from '../../components/AddOnCard/AddOnCard';
import css from './EditListingClusterForm.module.css';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

const ClusterAddOnList = (props) => {
    const { addOns = [], actions, intl, prefixRenderComponent } = props;

    return React.Children.toArray(
        addOns.map((addOn, index) => {
            return (
                <AddOnCard
                    className={css.addOnCard}
                    intl={intl}
                    addOn={addOn}
                    prefixComponentRender={prefixRenderComponent}
                    headingComponentRender={actions}
                    showDivider={index + 1 !== addOns.length}
                />
            );
        })
    );
};

ClusterAddOnList.propTypes = {};

export default compose(injectIntl)(ClusterAddOnList);
