import PropTypes from 'prop-types';
import React from 'react';

import {
    Button,
    FieldCurrencyInput,
    FieldSelect,
    FieldTextInput,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';

import css from './EditListingClusterForm.module.css';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as validators from '../../util/validators';
import config from '../../config';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../util/types';
import classNames from 'classnames';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { DEFAULT_ADDONS_TITLES } from '../../data/addOns';
import {
    getIntlTypeOfChargeOptionLabel,
    TYPE_OF_CHARGE_OPTIONS,
} from '../../data/typeOfCharges';
import { CLUSTER_SECTION } from './ClusterForm';
const { Money } = sdkTypes;

const AddOnForm = (props) => {
    const { intl } = props;

    const requiredFieldError = intl.formatMessage({
        id: 'EditListingAddOnForm.description',
    });
    const requiredFieldValidator = validators.required(requiredFieldError);

    const titleInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.title',
    });
    const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.titleInputPlaceholder',
    });

    const typeOfChargeInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfCharge',
    });
    const typeOfChargePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfChargeInputPlaceholder',
    });

    const descriptionInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.description',
    });
    const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.descriptionInputPlaceholder',
    });
    const descriptionMaxLimitInfoMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.descriptionInputMaxLimitInfo',
    });

    const cancelBtnLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.cancelBtnLabel',
    });

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const translationKey = isNightly
        ? 'EditListingAddOnForm.pricePerNight'
        : isDaily
            ? 'EditListingAddOnForm.pricePerDay'
            : 'EditListingAddOnForm.pricePerUnit';
    const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
    });

    const priceRequired = validators.required(
        intl.formatMessage({
            id: 'EditListingAddOnForm.priceRequired',
        })
    );

    const minPrice = new Money(
        config.listingMinimumPriceSubUnits,
        config.currency
    );

    const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
            {
                id: 'EditListingAddOnForm.priceTooLow',
            },
            {
                minPrice: formatMoney(intl, minPrice),
            }
        ),
        config.listingMinimumPriceSubUnits
    );

    const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

    const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.priceInputPlaceholder',
    });

    const { handleOnCancelAddOnForm, initialValues, onSubmit, currentAddonCluster } = props;
    const isDefaultAddOn = DEFAULT_ADDONS_TITLES.includes(initialValues?.title);

    return (
        <FinalForm
            {...props}
            render={(formRenderProps) => {
                const {
                    disabled,
                    ready,
                    invalid,
                    pristine,
                    updated,
                    updateInProgress,
                    values,
                    setTab,
                } = formRenderProps;

                const submitInProgress = updateInProgress;
                const subfieldsSubmitDisabled =
                    invalid || disabled || submitInProgress;
                const submitDisabled = subfieldsSubmitDisabled;
                const submitReady = !values?.newEdit
                    ? (!submitDisabled && updated && pristine) ||
                    (!submitDisabled && ready)
                    : false;

                return (
                    <div>
                        <FieldTextInput
                            type="text"
                            id="customAddonTitle"
                            name="title"
                            className={css.title}
                            label={titleInputLabel}
                            placeholder={titlePlaceholderMessage}
                            validate={requiredFieldValidator}
                            disabled={isDefaultAddOn}
                        />
                        <FieldTextInput
                            type="textarea"
                            id="customAddonDescription"
                            name="description"
                            label={descriptionInputLabel}
                            placeholder={descriptionPlaceholderMessage}
                            className={css.title}
                        />
                        <FieldSelect
                            id="typeOfCharge"
                            name="typeOfCharge"
                            className={css.typeOfCharge}
                            label={typeOfChargeInputLabel}
                            validate={requiredFieldValidator}>
                            <option disabled value="">
                                {typeOfChargePlaceholderMessage}
                            </option>
                            {TYPE_OF_CHARGE_OPTIONS.map((c) => (
                                <option key={c} value={c}>
                                    {getIntlTypeOfChargeOptionLabel(c, intl)}
                                </option>
                            ))}
                        </FieldSelect>
                        <FieldCurrencyInput
                            id="price"
                            name="price"
                            className={css.priceInput}
                            label={pricePerUnitMessage}
                            placeholder={pricePlaceholderMessage}
                            currencyConfig={config.currencyConfig}
                            validate={priceValidators}
                        />
                        <div className={css.earningsContainer}>
                            <p className={css.earnings}><FormattedMessage id='EditListingAddOnForm.earnings' /> {values?.price?.currency}{values?.price?.amount ? (values?.price?.amount * 0.95 / 100).toFixed(2) : "N/A"}</p>
                            <p className={css.earnings}><FormattedMessage id='EditListingAddOnForm.listingPrice' /> {values?.price?.currency}{values?.price?.amount ? (values?.price?.amount * 1.05 / 100).toFixed(2) : "N/A"}</p>
                        </div>
                        <div className={css.subFieldsBtnContainer}>
                            <Button
                                className={classNames(
                                    css.submitButton,
                                    css.subFieldsSubmitBtn
                                )}
                                type="button"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}
                                onClick={() => {
                                    const isEdit = window.localStorage.getItem('isEditAddon');
                                    window.localStorage.removeItem('isEditAddon');
                                    const updatedValue = {
                                        addOn: {
                                            description: values.description,
                                            price: values.price,
                                            title: values.title,
                                            typeOfCharge: values.typeOfCharge,
                                            uuid: values.uuid,
                                            isNewAddOn: isEdit == 'yes' ? false : true,
                                            currentAddonCluster: currentAddonCluster,
                                        }
                                    };
                                    if (values?.isClusterDraft) {
                                        updatedValue.cluster = {
                                            draftCluster: {
                                                uuid: values?.isClusterDraft,
                                                nextSection:
                                                    CLUSTER_SECTION.ADD_ON,
                                            },
                                        };
                                    }
                                    onSubmit(updatedValue);
                                    handleOnCancelAddOnForm();
                                    // setTab('activity');
                                }}>
                                Save
                            </Button>
                            <Button
                                type="button"
                                className={classNames(
                                    css.submitButton,
                                    css.subFieldsCancelBtn
                                )}
                                onClick={handleOnCancelAddOnForm}>
                                {cancelBtnLabel}
                            </Button>
                        </div>
                    </div>
                );
            }}
        />
    );
};

AddOnForm.propTypes = {};

export default compose(injectIntl)(AddOnForm);
