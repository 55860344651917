import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CloseDropdownIcon.module.css';

const CloseDropdownIcon = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.45013 8.55046C7.75501 8.85378 8.24499 8.85378 8.54987 8.55046L14.7719 2.32841C15.076 2.02353 15.076 1.53199 14.7719 1.22866C14.4678 0.92378 13.9755 0.92378 13.6722 1.22866L8 6.90006L2.32782 1.22866C2.02372 0.92378 1.5314 0.92378 1.22808 1.22866C0.923974 1.53199 0.923974 2.02353 1.22808 2.32841L7.45013 8.55046Z"
                fill="#4A4A4A"
                stroke="#4A4A4A"
            />
        </svg>
    );
};

const { string } = PropTypes;

CloseDropdownIcon.defaultProps = {
    className: null,
    rootClassName: null,
};

CloseDropdownIcon.propTypes = {
    className: string,
    rootClassName: string,
};

export default CloseDropdownIcon;
