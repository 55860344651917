import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as StopIcon } from './stop.svg';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import css from './InfoBox.module.css';

const InfoBox = (props) => {
    const { title, content, icon, withDropdown } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    return !withDropdown ? <div className={css.root}>
        <div>{icon ? icon : <InfoIcon />}</div>
        <div>
            {title}
            {content && <div>
                {content}
            </div>}
        </div>
    </div> : <div className={css.dropdownRoot}>
        <div
            className={css.topWrapper}
            onClick={() => setDropdownOpen(!dropdownOpen)}
        >
            <div className={css.topTitleWrapper}>
                {icon ? icon : <StopIcon />}
                <div>{title}</div>
            </div>
            <div style={dropdownOpen ? { transform: "rotateX(180deg)" } : {}}>
                <ArrowIcon />
            </div>
        </div>
        {content && dropdownOpen ? <div className={css.bottomWrapper}>
            {content}
        </div> : null}
    </div>
};

export default InfoBox;
