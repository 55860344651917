import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MapIcon from '../../assets/icons/map-icon.svg';

const IconMap = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={MapIcon} alt="map icon" />;
};

const { string } = PropTypes;

IconMap.defaultProps = {
    className: null,
    rootClassName: null,
};

IconMap.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconMap;
