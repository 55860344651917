import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
    {
        id: 'default-zurich',
        predictionPlace: {
            address: 'Zürich, Switzerland',
            bounds: new LatLngBounds(
                new LatLng(47.694840096,8.984951087),
                new LatLng(47.159442901,8.3577789)
            ),
        },
    },
    {
        id: 'default-bern',
        predictionPlace: {
            address: 'Bern, Bern, Switzerland',
            bounds: new LatLngBounds(
                new LatLng(46.99027,7.49555),
                new LatLng(46.919033,7.294318)
            ),
        },
    },
    {
        id: 'default-basel',
        predictionPlace: {
            address: 'Basel, Basel-Stadt, Switzerland',
            bounds: new LatLngBounds(
                new LatLng(47.589902,7.634148),
                new LatLng(47.519342,7.554664)
            ),
        },
    },
    {
        id: 'default-lucerne',
        predictionPlace: {
            address: 'Lucerne, Switzerland',
            bounds: new LatLngBounds(
                new LatLng(47.2871959251129,8.51371699123482),
                new LatLng(46.7750390354513,7.83663312731615)
            ),
        },
    },
    {
        id: 'default-geneva',
        predictionPlace: {
            address: 'Geneva, Geneva, Switzerland',
            bounds: new LatLngBounds(
                new LatLng(46.250648,6.17583),
                new LatLng(46.177774,6.086979)
            ),
        },
    },
];
export default defaultLocations;
