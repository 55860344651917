import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReturnIcon from '../../assets/icons/return-icon.svg';

const IconReturn = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={ReturnIcon} alt="ruler Icon" />;
};

const { string } = PropTypes;

IconReturn.defaultProps = {
    className: null,
    rootClassName: null,
};

IconReturn.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconReturn;
