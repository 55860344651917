import React from 'react';
import { ReactComponent as FileIcon } from './icons/file.svg';
import css from './ActivityFeed.module.css';

const FileComponent = (props) => {
    const {
        file_URL,
        file_name,
        fileSender,
        formattedDate,
    } = props;

    const isMine = fileSender && fileSender == "me";
    const isOthers = fileSender && fileSender == "other";

    return <div
        className={css.fileRoot}
        style={fileSender ? { margin: '20px 0' } : {}}
    >
        <div
            className={css.fileContainer}
            style={isMine ? { marginLeft: 'auto' } : {}}
        >
            <div className={css.fileIconWrapper}>
                <FileIcon />
            </div>
            <div className={css.fileInfo}>
                <div>{file_name}</div>
                <a
                    href={file_URL}
                    target='_blank'
                    style={isOthers ? { color: '#8e50ab' } : {}}
                >
                    Download
                </a>
            </div>
        </div>

        {formattedDate && <p
            className={css.ownMessageDate}
            style={isMine ? { marginLeft: 'auto' } : {}}
        >
            {formattedDate}
        </p>}
    </div>
};

export default FileComponent;
