import * as importedSdk from 'sharetribe-flex-sdk';

let exportSdk;

const isServer = () => typeof window === 'undefined';

if (isServer()) {
    // Use eval to skip webpack from bundling SDK in Node
    // eslint-disable-next-line no-eval
    exportSdk = eval('require')('sharetribe-flex-sdk');
} else {
    exportSdk = importedSdk;
}

const { createInstance, types, transit, util } = exportSdk;

export { createInstance, types, transit, util };
