import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = (props) => {
    const { className, checkedClassName, boxClassName } = props;
    return (
        <svg
            className={checkedClassName || css.checked}
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                fill=""
                stroke=""
                className={checkedClassName || css.checked}
            />
            <path
                d="M16.7355 8.21993C16.8037 8.1503 16.8852 8.09498 16.9751 8.05722C17.065 8.01945 17.1615 8 17.259 8C17.3565 8 17.453 8.01945 17.5429 8.05722C17.6328 8.09498 17.7142 8.1503 17.7825 8.21993C18.0685 8.50893 18.0725 8.97593 17.7925 9.26993L11.8795 16.2599C11.8124 16.3337 11.7309 16.3929 11.6401 16.4341C11.5493 16.4752 11.451 16.4974 11.3513 16.4993C11.2516 16.5011 11.1526 16.4827 11.0603 16.4449C10.968 16.4072 10.8843 16.3511 10.8145 16.2799L7.21649 12.6339C7.07773 12.4924 7 12.3021 7 12.1039C7 11.9057 7.07773 11.7154 7.21649 11.5739C7.28474 11.5043 7.36619 11.449 7.45608 11.4112C7.54597 11.3735 7.64249 11.354 7.73999 11.354C7.83749 11.354 7.93401 11.3735 8.0239 11.4112C8.11379 11.449 8.19525 11.5043 8.26349 11.5739L11.3155 14.6669L16.7155 8.24193C16.7217 8.23421 16.7284 8.22686 16.7355 8.21993Z"
                fill="white"
            />
        </svg>
    );
};

IconCheckbox.defaultProps = {
    className: null,
    checkedClassName: null,
    boxClassName: null,
};

IconCheckbox.propTypes = {
    className: string,
    checkedClassName: string,
    boxClassName: string,
};

const FieldCheckboxComponent = (props) => {
    const {
        rootClassName,
        className,
        svgClassName,
        textClassName,
        id,
        label,
        useSuccessColor,
        redStar,
        onChange,
        checked,
        ...rest
    } = props;

    const classes = classNames(rootClassName || css.root, className);

    // This is a workaround for a bug in Firefox & React Final Form.
    // https://github.com/final-form/react-final-form/issues/134
    const handleOnChange = (input, event) => {
        const { onBlur, onChange } = input;
        onChange(event);
        onBlur(event);
    };

    const successColorVariantMaybe = useSuccessColor
        ? {
            checkedClassName: css.checkedSuccess,
            boxClassName: css.boxSuccess,
        }
        : {};

    return (
        <span className={classes}>
            <Field type="checkbox" {...rest}>
                {(props) => {
                    const input = props.input;
                    return (
                        <input
                            id={id}
                            className={css.input}
                            {...input}
                            // checked={checked} // this two lines were blocking the checboxes from being checked
                            // onChange={(event) => onChange ? onChange(event) : handleOnChange(input, event)}
                            onChange={event =>  handleOnChange(input, event)} 
                        />
                    );
                }}
            </Field>
            <label htmlFor={id} className={css.label}>
                <span className={css.checkboxWrapper}>
                    <IconCheckbox
                        className={svgClassName}
                        {...successColorVariantMaybe}
                    />
                </span>
                <span
                    className={classNames(
                        css.text,
                        textClassName || css.textRoot
                    )}
                >
                    {label}  {redStar && <span style={{ color: "red" }}>*</span>}
                </span>
            </label>
        </span>
    );
};

FieldCheckboxComponent.defaultProps = {
    className: null,
    rootClassName: null,
    svgClassName: null,
    textClassName: null,
    label: null,
};

FieldCheckboxComponent.propTypes = {
    className: string,
    rootClassName: string,
    svgClassName: string,
    textClassName: string,

    // Id is needed to connect the label with input.
    id: string.isRequired,
    label: node,

    // Name groups several checkboxes to an array of selected values
    name: string.isRequired,

    // Checkbox needs a value that is passed forward when user checks the checkbox
    value: string.isRequired,
};

export default FieldCheckboxComponent;
