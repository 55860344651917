import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import { DeclineOrCancelOptions } from '../../util/misc';
import { Modal, Button, FieldTextInput, FieldSelect } from '../../components';
import css from './DeclineOfferModal.module.css';

const DeclineOfferModal = (props) => {
    const {
        isOpen,
        onClose,
        intl,
        onManageDisableScrolling,
        onSubmit,
    } = props;

    return <div className={css.root}>
        <Modal
            id="DeclineOfferModal"
            isOpen={isOpen}
            onClose={onClose}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            usePortal
        >
            <FinalForm
                {...props}
                onSubmit={(e) => onSubmit(e)}
                render={(fieldRenderProps) => {
                    const {
                        handleSubmit,
                        invalid,
                        pristine,
                        submitting,
                        values,
                    } = fieldRenderProps;

                    const required = validators.required(
                        'This field is required'
                    );
                    const submitDisabled =
                        invalid || pristine || submitting;

                    return <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}>
                        <h2 className={css.title}>
                            <FormattedMessage id="DeclineOfferModal.title" />
                        </h2>
                        <FieldSelect
                            id="declineOption"
                            name="declineOption"
                            label=""
                            validate={required}
                        >
                            <option value="">Select options</option>
                            {DeclineOrCancelOptions.map(option => {
                                return <option value={option}>
                                    {option}
                                </option>
                            })}
                            <option value="other">
                                Other (Please specify)
                            </option>
                        </FieldSelect>

                        {values.declineOption && values.declineOption == "other" ? <FieldTextInput
                            id="reasonOfDecline"
                            name="reasonOfDecline"
                            className={css.generalInput}
                            label={intl.formatMessage({
                                id: 'DeclineOfferModal.reason',
                            })}
                            placeholder=""
                            validate={required}
                        /> : null}

                        <Button
                            className={css.nextButton}
                            type="submit"
                            disabled={submitDisabled}
                        >
                            {intl.formatMessage({
                                id: 'DeclineOfferModal.button',
                            })}
                        </Button>
                    </form>
                }}
            />
        </Modal>
    </div>
};

export default DeclineOfferModal;
