import React from 'react';
import PropTypes from 'prop-types';
import chatIcon from '../../assets/icons/chat-icon.svg';

import css from './IconChat.module.css';
import classNames from 'classnames';

const IconChat = (props) => {
    const { className, rootClassName, ...rest } = props;
    const classes = classNames(rootClassName || css.root, className);

    return <img className={classes} src={chatIcon} {...rest} alt="chat icon" />;
};

IconChat.propTypes = {};

export default IconChat;
