/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';

import { CLUSTER_TABS } from './EditListingClusterForm';
import ClusterAddOnList from './ClusterAddOnList';

import css from './EditListingClusterForm.module.css';
import { FieldCheckbox } from '../../components';

const ClusterAddOnsForm = (props) => {
    const {
        cluster,
        renderClusterFormActionBtn,
        submitLabelId,
        addOns = [],
        handleOnCreatNewAddOn,
        handleOnClickManageAddOn,
    } = props;

    const createANewAddOnLink = (className) => (
        <a
            className={classNames(className || '')}
            onClick={handleOnCreatNewAddOn}>
            <FormattedMessage id="EditListingAddOnForm.createNewOneLabel" />
        </a>
    );

    const addOnCheckboxRender = ({ uuid }) => (
        <FieldCheckbox
            className={css.activityAddOnPrefixCheckbox}
            id={uuid}
            name="addOns"
            value={uuid}
        />
    );

    return (
        <>
            <div>
                <ClusterAddOnList
                    addOns={addOns}
                    prefixRenderComponent={addOnCheckboxRender}
                />
            </div>
            {!addOns.length ? (
                <p>
                    <FormattedMessage id="EditListingAddOnForm.noAddOnsMessage" />
                    {', '}
                    {createANewAddOnLink()}
                </p>
            ) : (
                <div>
                    <a
                        className={css.clusterAddOnLink}
                        onClick={handleOnClickManageAddOn}>
                        <FormattedMessage
                            id={`EditListingAddOnForm.clusterTabTitle.${CLUSTER_TABS.ADD_ON}`}
                        />
                    </a>
                    {createANewAddOnLink(css.clusterAddOnLink)}
                </div>
            )}

            {renderClusterFormActionBtn(null, null, submitLabelId)}
        </>
    );
};

ClusterAddOnsForm.propTypes = {};

export default compose(injectIntl)(ClusterAddOnsForm);
