import React, { useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { Button, Modal } from '../../../components';
import css from './ProviderInquiryActions.module.css';

const ProviderInquiryActions = (props) => {
    const {
        listing,
        noOfGuest,
        customBadgeComponent,
        customBreakdownComponent,
        intl,
        onManageDisableScrolling,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const price = listing.attributes.price;
    const formattedPrice = formatMoney(intl, price);

    return <div className={css.root}>
        <div className={css.priceContainer}>
            <div className={css.priceValue} title={formattedPrice}>
                {formattedPrice}
            </div>
            <div className={css.perUnit}>
                <FormattedMessage id='BookingPanel.perUnit' />
            </div>
            {customBadgeComponent}
        </div>

        <div className={css.actionButtonsWrapper}>
            <Button
                rootClassName={css.bookButton}
                onClick={() => setModalOpen(true)}
            >
                <FormattedMessage id="BookingPanel.ctaButtonMessageBook" />
            </Button>
        </div>

        <Modal
            id="ProviderInquiryActions"
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            usePortal
        >
            <div className={css.noOfGuest}>
                <FormattedMessage id="OfferModal.noOfGuests" />: {noOfGuest}
            </div>
            {customBreakdownComponent}
        </Modal>
    </div>
};

export default ProviderInquiryActions;
