import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import config from '../../config';
import { ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

class ListingImage extends Component {
    render() {
        return <ResponsiveImage {...this.props} />;
    }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
    loadAfterInitialRendering: 3000,
});

export const RedirectingListingCardComponent = (props) => {
    const {
        className,
        rootClassName,
        image,
    } = props;

    const classes = classNames(
        rootClassName || css.root,
        className,
        css.listCard
    );

    return (
        <>
            
        </>
        // <a href='https://robin-room.com/' className={classes} target="__blank">
        //     <div className={css.threeToTwoWrapper}>
        //         <div >
        //             <img className={css.lastImage} src={image}></img>
        //         </div>
        //     </div>
        //     <div className={css.info}>
        //         <div className={css.mainInfo}>
        //             <div className={css.title}>
        //                 <FormattedMessage id="RedirectingListingCard.title" />
        //             </div>
        //             <div
        //                 className={css.listInfo}
        //                 style={{ color: 'var(--matterColor)' }}>
        //                 <div className={css.listInfoItem}>
        //                     <FormattedMessage id="RedirectingListingCard.description" />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </a>
    );
};

RedirectingListingCardComponent.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: null,
    filtersConfig: config.custom.filters,
    setActiveListing: () => null,
};

RedirectingListingCardComponent.propTypes = {
    className: string,
    rootClassName: string,
    filtersConfig: array,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,

    // Responsive image sizes hint
    renderSizes: string,

    setActiveListing: func,
};

export default injectIntl(RedirectingListingCardComponent);
