import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';

import { types as sdkTypes } from '../../util/sdkLoader';
import ClusterCardListForm from './ClusterListingForm';
import ClusterForm from './ClusterForm';

const { Money } = sdkTypes;

const ClusterTab = (props) => {
    const { clusters, setClusterState, setEditClusterId } = props;

    const handleOnCreateNewCluster = () => {
        const newCluster = {
            uuid: uuid(),
            isDraft: true,
        };
        setClusterState(newCluster);
    };

    const onEditCluster = (_uuid) => {
        const existingCluster = clusters.find((_clus) => _clus.uuid === _uuid);
        if (existingCluster) {
            const newCluster = {
                ...existingCluster,
                newEdit: true,
            };
            if (newCluster.price) {
                newCluster.price = new Money(
                    newCluster.price.amount,
                    newCluster.price.currency
                );
            }
            setClusterState(newCluster);
            setEditClusterId(_uuid)
        }
    };
    const isClusterExistFunc = (_clus) =>
        clusters.find((_c) => _c.label === _clus.label);

    const {
        addOns,
        filterConfig,
        clusterState,
        onManageDisableScrolling,
        onSubmit,
        handleOnCreatNewAddOn,
        handleOnCancelClusterForm,
    } = props;

    const showClusterForm = clusterState;

    const handleOnDeleteCluster = (_clus) => {
        if (
            window &&
            window.confirm(`Confirm delete Cluter:"${_clus.title}"?`) === true
        ) {
            const newClusters = clusters.filter((_c) => _c.uuid !== _clus.uuid);
            onSubmit({ clusters: newClusters });
        }
    };

    return (
        <div style={{ marginBottom: '2rem' }}>
            {showClusterForm ? (
                <ClusterForm
                    addOns={addOns}
                    clusters={clusters}
                    onSubmit={onSubmit}
                    initialValues={clusterState}
                    filterConfig={filterConfig}
                    isClusterExistFunc={isClusterExistFunc}
                    handleOnCancelClusterForm={handleOnCancelClusterForm}
                    handleOnCreatNewAddOn={handleOnCreatNewAddOn}
                    {...props}
                />
            ) : (
                <ClusterCardListForm
                    clusters={clusters}
                    addOns={addOns}
                    onCreateNewCluster={handleOnCreateNewCluster}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onEditCluster={onEditCluster}
                    onDeleteCluster={handleOnDeleteCluster}
                    {...props}
                />
            )}
        </div>
    );
};

ClusterTab.propTypes = {};

export default ClusterTab;
