import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import css from './ClusterCard.module.css';
import IconTime from '../IconTime/IconTime';
import IconGroupPpl from '../IconGroupPpl/IconGroupPpl';
import IconBolt from '../IconBolt/IconBolt';
import IconCleaning from '../IconCleaning/IconCleaning';
import IconPerson from '../IconPerson/IconPerson';
import classNames from 'classnames';
import IconGift from '../IconGift/IconGift';
import { FormattedMessage } from 'react-intl';
import IconInfo from '../IconInfo/IconInfo';
import Modal from '../Modal/Modal';
import { GET_ACTIVITY_LIST } from '../../data/clusters';
import IconActivities from '../IconActivities/IconActivities';
import { getIntlTypeOfChargeOptionLabel } from '../../data/typeOfCharges';

const { Money } = sdkTypes;

const ClusterInfoField = ({ label, value, divider = false, row = false }) => (
    <div
        className={classNames(
            row ? css.activityInfoRowField : css.activityInfoColumnField,
            divider ? css.bottomDivider : ''
        )}>
        <div className={css.activityInfoFieldTitle}>{label}</div>
        <div className={css.activityInfoFieldValue}>{value}</div>
    </div>
);

const ClusterCard = (props) => {
    const {
        cluster,
        addOns = [],
        headingComponentRender,
        headingContainerClassName,
        headingContainerRootClassName,
        intl,
        onManageDisableScrolling,
        showInfoIcon = false,
    } = props;
    const {
        title,
        maxNoOfGuest,
        price,
        additionalInfo,
        cancellationPolicy,
        minBookingDuration,
        feature_keys,
        activities_key,
    } = cluster;

    const clAddons = cluster.addOns || [];
    const [openInfoModal, setOpenInfoModal] = useState(false);

    const perHourLabel = (
        <FormattedMessage id="EditListingAddOnForm.typeOfChargeOption.perHour" />
    );

    const minBookingDurationLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.minBookingDurationLabel" />
    );
    const maxNoOfGuestLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.maxNoOfGuestLabel" />
    );

    const allowInstantBookingLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.allowInstantBookingLabel" />
    );

    const includeCleaningLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.includeCleaningLabel" />
    );

    const allowSelfCateringLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.allowSelfCateringLabel" />
    );
    const activitiesLabel = (
        <FormattedMessage id="ClusterCard.activityDetails.activitiesLabel" />
    );

    const minBookingUnitText = intl.formatMessage({
        id: 'ClusterCard.activityDetails.minBookingUnitText',
    });

    const actAddOns = addOns.filter((_add) =>
        cluster?.addOns?.includes(_add.uuid)
    );

    const custerActivities = GET_ACTIVITY_LIST().filter((_a) =>
        activities_key?.includes(_a.key)
    );

    const headingTitle = <h3 className={css.activityTitle}>{title}</h3>;
    const allowInstantBooking = feature_keys?.includes('allowInstantBooking');
    const includeCleaning = feature_keys?.includes('includeCleaning');
    const allowSelfCatering = feature_keys?.includes('allowSelfCatering');

    const activityInfoDetails = () => {
        const formattedPrice = formatMoney(
            intl,
            new Money(price.amount * 1.1, price.currency)
        );

        return (
            <div className={css.activityInfoContainer}>
                <div className={css.activityInfoTitle}>{title}</div>

                {custerActivities.length ? (
                    <ClusterInfoField
                        label={activitiesLabel}
                        value={React.Children.toArray(
                            custerActivities.map((_act) => (
                                <li className={css.activityInfoFieldValue}>
                                    {_act.label}
                                </li>
                            ))
                        )}
                    />
                ) : null}

                <ClusterInfoField
                    divider
                    label={
                        <FormattedMessage id="EditListingAddOnForm.activityPriceInputLabel" />
                    }
                    value={formattedPrice}
                />

                <ClusterInfoField
                    divider
                    label={
                        <FormattedMessage id="ClusterCard.activityDetails.minBookingDurationLabel" />
                    }
                    value={`${minBookingDuration} ${minBookingUnitText}`}
                />
                <div className={css.activityInfoFieldRowContainer}>
                    {allowInstantBooking && (
                        <ClusterInfoField
                            row
                            label={
                                <IconBolt className={css.activityInfoIcon} />
                            }
                            value={
                                <FormattedMessage id="ClusterCard.activityDetails.instantBookingValue" />
                            }
                        />
                    )}
                    {includeCleaning && (
                        <ClusterInfoField
                            row
                            label={
                                <IconCleaning
                                    className={css.activityInfoIcon}
                                />
                            }
                            value={
                                <FormattedMessage id="ClusterCard.activityDetails.cleaningValue" />
                            }
                        />
                    )}
                    {allowSelfCatering && (
                        <ClusterInfoField
                            row
                            label={
                                <IconPerson className={css.activityInfoIcon} />
                            }
                            value={
                                <FormattedMessage id="ClusterCard.activityDetails.selfCateringValue" />
                            }
                        />
                    )}
                    {cancellationPolicy &&
                        <ClusterInfoField
                            divider
                            label={
                                <FormattedMessage id="EditListingAddOnForm.activityCancellationPolicyInfoLabel" />
                            }
                            value={cancellationPolicy}
                        />
                    }
                </div>
                {addOns.length ? (
                    <ClusterInfoField
                        label={
                            <FormattedMessage id="ClusterCard.activityDetails.addOnsLabel" />
                        }
                        value={React.Children.toArray(
                            addOns.map((_add) => {
                                if (!clAddons.includes(_add.uuid)) { return null };

                                const formattedPrice = formatMoney(
                                    intl,
                                    new Money(
                                        _add.price.amount,
                                        _add.price.currency
                                    )
                                );

                                const typeOfCharge =
                                    getIntlTypeOfChargeOptionLabel(
                                        _add.typeOfCharge,
                                        intl
                                    );

                                return (
                                    <li className={css.activityInfoFieldValue}>
                                        {_add.title} - {formattedPrice}
                                        {typeOfCharge}
                                    </li>
                                );
                            })
                        )}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <div className={css.activityContainer}>
            <Modal
                id="activityCardInfoModal"
                isOpen={openInfoModal}
                onClose={() => setOpenInfoModal(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                usePortal>
                {activityInfoDetails()}
            </Modal>
            <div
                className={classNames(
                    headingContainerRootClassName || css.headingComponentRoot,
                    headingContainerClassName
                )}>
                {headingComponentRender && headingComponentRender(cluster)}
                {showInfoIcon && (
                    <IconInfo
                        className={css.infoIcon}
                        onClick={() => setOpenInfoModal(true)}
                    />
                )}
            </div>
            {headingTitle}
            <div className={css.activityDetailsContainer}>
                <div className={css.activityInfos}>
                    <div className={css.activityCardDetailsFieldsContainer}>
                        {custerActivities.length ? (
                            <div
                                className={classNames(
                                    css.activityCardDetailsField,
                                    css.rowInfoFields
                                )}>
                                <IconActivities />
                                {React.Children.toArray(
                                    custerActivities.map(
                                        (_add, index) =>
                                            `${_add.label}${index ===
                                                custerActivities.length - 1
                                                ? ''
                                                : ', '
                                            }`
                                    )
                                )}
                            </div>
                        ) : null}
                        <div className={css.activityCardDetailsField}>
                            <IconTime />
                            {minBookingDurationLabel}{' '}
                            <span className={css.activityCardDetailsFieldValue}>
                                {minBookingDuration} hour/s
                            </span>
                        </div>
                        <div className={css.activityCardDetailsField}>
                            <IconGroupPpl />
                            {maxNoOfGuestLabel}
                            <span className={css.activityCardDetailsFieldValue}>
                                {' '}
                                {maxNoOfGuest}
                            </span>
                        </div>
                        {allowInstantBooking && (
                            <div className={css.activityCardDetailsField}>
                                <IconBolt />
                                {allowInstantBookingLabel}
                            </div>
                        )}
                        {includeCleaning && (
                            <span className={css.activityCardDetailsField}>
                                <IconCleaning />
                                {includeCleaningLabel}
                            </span>
                        )}
                        {allowSelfCatering && (
                            <span className={css.activityCardDetailsField}>
                                <IconPerson />
                                {allowSelfCateringLabel}
                            </span>
                        )}
                        {actAddOns.length ? (
                            <div
                                className={classNames(
                                    css.activityCardDetailsField,
                                    css.rowInfoFields
                                )}>
                                <IconGift />
                                {React.Children.toArray(
                                    actAddOns.map(
                                        (_add, index) =>
                                            `${_add.title}${index === actAddOns.length - 1
                                                ? ''
                                                : ', '
                                            }`
                                    )
                                )}
                            </div>
                        ) : null}
                        <p className={css.actvitiyDescription}>
                            {additionalInfo}
                        </p>
                    </div>
                </div>
                <div className={css.activityPriceContainer}>
                    <span className={css.activityPriceText}>{`${formatMoney(
                        intl,
                        new Money(price.amount, price.currency),
                        1.1
                    )}`}</span>
                    <span className={css.perHourLabel}>{perHourLabel}</span>
                </div>
            </div>
        </div>
    );
};

ClusterCard.propTypes = {};

export default ClusterCard;
