import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './EditListingClusterForm.module.css';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';
import {
    FieldSelect,
    FieldTextInput,
    FieldCurrencyInput,
    FieldCheckbox,
    NamedLink,
} from '../../components';

import { types as sdkTypes } from '../../util/sdkLoader';
import { CLUSTER_SECTION } from './ClusterForm';
import config from '../../config';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
const { Money } = sdkTypes;
const ClusterDetailsForm = (props) => {
    const {
        intl,
        filterConfig,
        cluster,
        isClusterEdit,
        toggleActivitySectionTab,
        nextSection,
        submitLabel,
        cancelBtnLabel,
        isClusterExistFunc,
        renderClusterFormActionBtn,
        disabledNextBtn,
        submitLabelId,
        values,
        clusters,
    } = props;

    const [currentCluster, setCurrentCluster] = useState(values.title);

    const requiredFieldError = intl.formatMessage({
        id: 'EditListingAddOnForm.description',
    });
    const requiredFieldValidator = validators.required(requiredFieldError);

    const typeOfClusterInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfClusterInputLabel',
    });
    const typeOfClusterPlaceholder = intl.formatMessage({
        id: 'EditListingAddOnForm.typeOfClusterPlaceholder',
    });

    const activityPriceInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityPriceInputLabel',
    });

    const activityMinBookingInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityMinBookingInputLabel',
    });
    const activityMinBookingPlaceholder = intl.formatMessage({
        id: 'EditListingAddOnForm.activityMinBookingPlaceholder',
    });

    const activityMaxNoOfGuestInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityMaxNoOfGuestInputLabel',
    });
    const activityMaxNoOfGuestPlaceholder = intl.formatMessage({
        id: 'EditListingAddOnForm.activityMaxNoOfGuestPlaceholder',
    });

    const activityAdditionalInfoInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityAdditionalInfoInputLabel',
    });
    const activityAdditionalInfoPlaceholder = intl.formatMessage({
        id: 'EditListingAddOnForm.activityAdditionalInfoPlaceholder',
    });

    const activityCancellationPolicyInputLabel = <div >
        <span>{intl.formatMessage({
            id: 'EditListingAddOnForm.activityCancellationPolicyInputLabel',
        })} <a href='https://robin-room.com/cancellation-templates/' target="_blank"
        ><span className={css.cancellationWrapper}>ⓘ
                    <div className={css.cancellationToolTip}>{intl.formatMessage({
                        id: 'EditListingAddOnForm.activityCancellationPolicyToolTip',
                    })}</div>
                </span></a></span>

    </div>;
    const activityCancellationPolicyPlaceholder = intl.formatMessage({
        id: 'EditListingAddOnForm.activityCancellationPolicyPlaceholder',
    });

    const activityIncludeCleaningInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityIncludeCleaningInputLabel',
    });

    const activityAllowInstantBookingInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityAllowInstantBookingInputLabel',
    });

    const activityAllowSelfCateringInputLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.activityAllowSelfCateringInputLabel',
    });

    const activityCancelPolicyInfoText = intl.formatMessage({
        id: 'EditListingAddOnForm.activityCancelPolicyInfoText',
    });

    const instantBookingText = intl.formatMessage({
        id: 'EditListingAddOnForm.instantBookingText',
    });

    // const activityNextBtnText = intl.formatMessage({
    //     id: 'EditListingAddOnForm.activityNextBtnText',
    // });

    const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddOnForm.priceInputPlaceholder',
    });

    const priceRequired = validators.required(
        intl.formatMessage({
            id: 'EditListingAddOnForm.priceRequired',
        })
    );

    const minPrice = new Money(
        config.listingMinimumPriceSubUnits,
        config.currency
    );

    const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
            {
                id: 'EditListingAddOnForm.priceTooLow',
            },
            {
                minPrice: formatMoney(intl, minPrice),
            }
        ),
        config.listingMinimumPriceSubUnits
    );

    const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

    const clusterOptions = findOptionsForSelectFilter(
        'activities',
        filterConfig
    );

    const DefaultAddOnBtn = (props) => {
        const { active, value, disabled, onClick, ...rest } = props || {};
        const handleOnClick = disabled ? () => { } : onClick;
        return (
            <a
                {...rest}
                onClick={handleOnClick}
                className={classNames(
                    css.defaultAddOnBtn,
                    disabled
                        ? css.defaultAddOnBtnDisabled
                        : active
                            ? css.defaultAddOnBtnActive
                            : ''
                )}>
                {value}
            </a>
        );
    };

    const handleOnCreateNewCluster = (cl) => {
        setCurrentCluster(cl.key);
        values.title = cl.key;
        values.activities_key = []
    };
    const checkTitle = (key) => {
        const result = clusters?.filter(c => {
            return c.title == key
        })
        return result.length > 0 ? true : false
    }

    return (
        <>
            <div className={css.defaultAddOnsContainer}>
                {React.Children.toArray(
                    clusterOptions.map((cl, index) => {

                        return <DefaultAddOnBtn
                            disabled={checkTitle(cl.label)}
                            onClick={() => handleOnCreateNewCluster(cl)}
                            value={cl.key}
                            active={cl.key === currentCluster}
                        />
                    }
                    )
                )}
            </div>
            <FieldSelect
                id="title"
                name="title"
                hidden={true}
                className={css.typeOfCharge}
                label={''}
                validate={requiredFieldValidator}>
                <option disabled value="">
                    {typeOfClusterPlaceholder}
                </option>
                {clusterOptions.map((_clus) => {
                    const show =
                        !!(isClusterEdit && _clus.key === values?.title) ||
                        !isClusterExistFunc(_clus);

                    return show ? (
                        <option key={_clus.key} value={_clus.key}>
                            {_clus.label}
                        </option>
                    ) : null;
                })}
            </FieldSelect>
            {values.title && <>
                <FieldCurrencyInput
                    id="price"
                    name="price"
                    className={css.priceInput}
                    label={activityPriceInputLabel}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    validate={priceValidators}
                />
                <div className={css.earningsContainer}>
                    <p className={css.earnings}><FormattedMessage id='ClusterDetailForm.earnings' /> {values?.price?.currency}{values?.price?.amount ? (values?.price?.amount * 0.9 / 100).toFixed(2) : "N/A"}</p>
                    <p className={css.earnings}><FormattedMessage id='ClusterDetailForm.listingPrice' /> {values?.price?.currency}{values?.price?.amount ? (values?.price?.amount * 1.1 / 100).toFixed(2) : "N/A"}</p>
                </div>
                <div className={css.desktop6Cols}>
                    <FieldTextInput
                        type="number"
                        id="minBookingDuration"
                        name="minBookingDuration"
                        className={css.title}
                        label={activityMinBookingInputLabel}
                        placeholder={activityMinBookingPlaceholder}
                        validate={requiredFieldValidator}
                    />

                    <FieldTextInput
                        type="number"
                        id="maxNoOfGuest"
                        name="maxNoOfGuest"
                        className={css.title}
                        label={activityMaxNoOfGuestInputLabel}
                        placeholder={activityMaxNoOfGuestPlaceholder}
                        validate={requiredFieldValidator}
                    />
                </div>

                <FieldTextInput
                    type="textarea"
                    id="additionalInfo"
                    name="additionalInfo"
                    className={css.title}
                    label={activityAdditionalInfoInputLabel}
                    placeholder={activityAdditionalInfoPlaceholder}
                />

                <FieldTextInput
                    type="textarea"
                    id="cancellationPolicy"
                    name="cancellationPolicy"
                    className={css.cancellationPoilicyTitle}
                    label={activityCancellationPolicyInputLabel}
                    placeholder={activityCancellationPolicyPlaceholder}
                    validate={requiredFieldValidator}
                    subLabel={<a href='https://robin-room.com/cancellation-templates/' target="_blank"
                    ><p style={{ fontSize: "14px", marginTop: 0, marginBottom: 0 }}>Cancellation Policy Examples</p></a>}
                />
                <FieldCheckbox
                    label={activityAllowInstantBookingInputLabel}
                    id="allowInstantBooking"
                    name="feature_keys"
                    value="allowInstantBooking"
                    redStar={true}>
                </FieldCheckbox>
                <FieldCheckbox
                    label={activityIncludeCleaningInputLabel}
                    id="includeCleaning"
                    name="feature_keys"
                    value="includeCleaning"
                />
                <FieldCheckbox
                    label={activityAllowSelfCateringInputLabel}
                    id="allowSelfCatering"
                    name="feature_keys"
                    value="allowSelfCatering"
                />
                <p className={css.cancelPolicyInfoText}>
                    <span className={css.requiredRedStar}>*</span>
                    {instantBookingText}
                </p>
                <p className={css.cancelPolicyInfoText}>
                    {activityCancelPolicyInfoText}
                </p>
            </>}
            {renderClusterFormActionBtn(
                disabledNextBtn && disabledNextBtn(values),
                CLUSTER_SECTION.ACTIVITIY,
                submitLabelId
            )}
        </>
    );
};

ClusterDetailsForm.propTypes = {};

export default compose(injectIntl)(ClusterDetailsForm);
