import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaxCapIcon from '../../assets/icons/maxcap-icon.svg';

const IconMaxCap = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={MaxCapIcon} alt="Max Capacity icon" />;
};

const { string } = PropTypes;

IconMaxCap.defaultProps = {
    className: null,
    rootClassName: null,
};

IconMaxCap.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconMaxCap;
