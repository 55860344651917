import React from 'react';
import { DEFAULT_ADDONS } from '../../data/addOns';
import { getIntlTypeOfChargeOptionLabel } from '../../data/typeOfCharges';
import EditIcon from '../../components/EditIcon/EditIcon';
import DeleteIcon from '../../components/DeleteIcon/DeleteIcon';
import css from './TxAddonCard.module.css';

const TxAddonCard = (props) => {
    const {
        intl,
        addon,
        onEditAddon,
        onRemoveAddon,
    } = props;

    const {
        description,
        price,
        title,
        typeOfCharge,
        uuid,
    } = addon;

    const isCustomAddOn = !DEFAULT_ADDONS.find((a) => a.title == title);

    return <div className={css.root}>
        <div className={css.leftPanel}>
            {isCustomAddOn && <div className={css.customAddonTitle}>
                Custom Add-on
            </div>}
            <div className={css.title}>
                {title}
            </div>
            {description && <div className={css.desc}>
                {description}
            </div>}
        </div>
        <div className={css.rightPanel}>
            <div className={css.actions}>
                <div onClick={() => onEditAddon(addon)}>
                    <EditIcon />
                </div>
                <div onClick={() => onRemoveAddon(uuid)}>
                    <DeleteIcon />
                </div>
            </div>
            <div className={css.price}>
                {(price.amount / 100).toFixed(2)}
            </div>
            <div className={css.charge}>
                {getIntlTypeOfChargeOptionLabel(typeOfCharge, intl)}
            </div>
        </div>
    </div>
};

export default TxAddonCard;
