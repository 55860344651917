import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoIcon from '../../assets/icons/info-icon.svg';

const IconInfo = (props) => {
    const { className, rootClassName, ...rest } = props;
    const classes = classNames(rootClassName, className);

    return (
        <img
            className={classes}
            src={InfoIcon}
            alt="Information icon"
            {...rest}
        />
    );
};

const { string } = PropTypes;

IconInfo.defaultProps = {
    className: null,
    rootClassName: null,
};

IconInfo.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconInfo;
