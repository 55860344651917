/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'react-uuid';

import { DEFAULT_ADDONS } from '../../data/addOns';
import { types as sdkTypes } from '../../util/sdkLoader';
import AddOnCardListForm from './AddOnCardList';
import AddOnForm from './AddOnForm';

import css from './EditListingClusterForm.module.css';

const { Money } = sdkTypes;

const DefaultAddOnBtn = (props) => {
    const { active, value, disabled, onClick, ...rest } = props || {};
    const handleOnClick = disabled ? () => { } : onClick;
    return (
        <a
            {...rest}
            onClick={handleOnClick}
            className={classNames(
                css.defaultAddOnBtn,
                disabled
                    ? css.defaultAddOnBtnDisabled
                    : active
                        ? css.defaultAddOnBtnActive
                        : ''
            )}>
            {value}
        </a>
    );
};

const AddOnsTab = (props) => {
    const {
        addOns,
        addOnState,
        setAddOnState,
        onSubmit,
        filterConfig,
        handleOnCancelAddOnForm,
        currentAddonCluster,
        setTab,
    } = props;

    const handleOnCreateNewAddOn = (_addOn) => {
        setAddOnState({ uuid: uuid(), isNewAddOn: true, ..._addOn, isClusterDraft: addOnState?.isClusterDraft });
    };

    const showAddOnForm = addOnState;

    const addOnTitles = addOns.map((a) => a.title);

    const handleOnEditAddOn = (_addOn) => {
        const isExistingAddOn = addOnTitles.includes(_addOn?.title);
        if (isExistingAddOn) {
            const selecteAddOn = { ..._addOn, isNewAddOn: false };

            if (selecteAddOn.price) {
                selecteAddOn.price = new Money(
                    selecteAddOn.price.amount,
                    selecteAddOn.price.currency
                );
            }
            setAddOnState(selecteAddOn);
        }
    };

    const handleOnDeleteAddOn = (_addOn) => {
        if (
            window &&
            window.confirm(`Confirm delete Add-on: "${_addOn.title}"?`) === true
        ) {
            const newAddOns = addOns.filter((_a) => _a.uuid !== _addOn.uuid);
            onSubmit({ addOns: newAddOns });
        }
    };
    return (
        <div>
            <div className={css.defaultAddOnsContainer}>
                {React.Children.toArray(
                    DEFAULT_ADDONS.map((_addOn, index) =>
                        addOnTitles.includes(_addOn.title) ? null : (
                            <DefaultAddOnBtn
                                disabled={addOnState && !addOnState?.isNewAddOn}
                                onClick={() => handleOnCreateNewAddOn(_addOn)}
                                value={_addOn.title}
                                active={_addOn.title === addOnState?.title}
                            />
                        )
                    )
                )}
                <DefaultAddOnBtn
                    disabled={false}
                    onClick={() => handleOnCreateNewAddOn('')}
                    value='Custom'
                    active={addOnState && !addOnState?.title}
                />
            </div>

            {showAddOnForm ? (
                <AddOnForm
                    onSubmit={onSubmit}
                    initialValues={addOnState}
                    filterConfig={filterConfig}
                    handleOnCancelAddOnForm={handleOnCancelAddOnForm}
                    currentAddonCluster={currentAddonCluster}
                    setTab={setTab}
                />
            ) : (
                <AddOnCardListForm
                    addOns={addOns}
                    setTab={setTab}
                    showCreateNewAddOn
                    handleOnCreateNewAddOn={handleOnCreateNewAddOn}
                    onEditAddOn={handleOnEditAddOn}
                    onDeleteAddOn={handleOnDeleteAddOn}
                />
            )}
        </div>
    );
};

AddOnsTab.propTypes = {};

export default compose(injectIntl)(AddOnsTab);
