import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = (props) => {
    const {
        showDetailCardHeadings,
        listingTitle,
        subTitle,
        location,
        geolocation,
        showAddress,
        phoneNumber,
        authorDisplayName,
        isProvider,
    } = props;

    return showDetailCardHeadings ? (
        <div className={css.detailCardHeadings}>
            {/* {!isProvider && <> */}
                <h2 className={css.detailCardTitle}>
                    {listingTitle} {isProvider ? '' : <span>
                        by {authorDisplayName}
                    </span>}
                </h2>
                {/* <p className={css.detailCardSubtitle}>{subTitle}</p> */}
            {/* </>} */}
            <AddressLinkMaybe
                location={location}
                geolocation={geolocation}
                showAddress={showAddress}
                phoneNumber={phoneNumber}
                isProvider={isProvider}
            />
        </div>
    ) : null;
};

export default DetailCardHeadingsMaybe;
