import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';

import {
    array,
    bool,
    func,
    node,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { BookingDataThroughSignUpKey } from '../../util/misc';
import { ReactComponent as PencilIcon } from '../ActivityFeed/icons/pencil.svg';
import { ReactComponent as CircleXIcon } from '../ActivityFeed/icons/circleX.svg';
import css from './BookingPanel.module.css';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
        const formattedPrice = formatMoney(intl, price, 1.1);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: `(${price.currency})`,
            priceTitle: `Unsupported currency (${price.currency})`,
        };
    }
    return {};
};

export const openBookModal = (isOwnListing, isClosed, history, location) => {
    if (isOwnListing || isClosed) {
        window.scrollTo(0, 0);
    } else {
        const { pathname, search, state } = location;
        const searchString = `?${stringify({ ...parse(search), book: true })}`;
        history.push(`${pathname}${searchString}`, state);
    }
};

export const closeBookModal = (history, location) => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'book');
    const searchString = `?${stringify(searchParams)}`;
    history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
};

const BookingPanel = (props) => {
    const {
        rootClassName,
        className,
        titleClassName,
        listing,
        isOwnListing,
        authorDisplayName,
        unitType,
        onSubmit,
        title,
        subTitle,
        onManageDisableScrolling,
        onFetchTimeSlots,
        monthlyTimeSlots,
        history,
        location,
        intl,
        onFetchTransactionLineItems,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        onContactUser,
        currentUser,
        specialAddOns,
        isListingPagePanel,
        savedBookingData,
        showInquiryForm,
        toggleShowInquiryForm,
        isInquiryAccepted,
        offerMade,
        customBreakdownComponent,
        customBadgeComponent,
        cancellationPolicyComponent,
        txIsSupported,
    } = props;

    const price = listing.attributes.price;
    const timeZone =
        listing.attributes.availabilityPlan &&
        listing.attributes.availabilityPlan.timezone;
    const hasListingState = !!listing.attributes.state;
    const isClosed =
        hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
    const showOpenPanelBtn = hasListingState && !isClosed;
    const showBookingTimeForm = showOpenPanelBtn &&
        (showInquiryForm && !isListingPagePanel || isListingPagePanel);

    const showClosedListingHelpText = listing.id && isClosed;
    const isBook = !!parse(location.search).book;

    const { clusters = [], addOns = [] } = listing.attributes.publicData || {};
    // merge clusters with their add on full details
    const listingActivities = clusters.map((act) => ({
        ...act,
        addOns: addOns.filter((add) => act?.addOns?.includes(add.uuid)),
    }));

    let corruptValues = false;
    if (savedBookingData) {
        const { bookingStartDate, bookingEndDate, activity } = savedBookingData;
        if (bookingStartDate) {
            savedBookingData.bookingStartDate.date = new Date(bookingStartDate.date);
        };
        if (bookingEndDate) {
            savedBookingData.bookingEndDate.date = new Date(bookingEndDate.date);
        };
        if (!listingActivities.map(a => a.uuid).includes(activity)) {
            corruptValues = true;
        };
    };

    const subTitleText = !!subTitle
        ? subTitle
        : showClosedListingHelpText
            ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
            : null;

    // fix booking unit to [per hour] for now
    const unitTranslationKey = 'BookingPanel.perUnit';

    const classes = classNames(rootClassName || css.root, className);
    const titleClasses = classNames(titleClassName || css.bookingTitle);
    const { formattedPrice: defaultFormattedPrice, priceTitle } = priceData(
        price,
        intl
    );

    const [bookingDataFromSignup, setBookingDataFromSignup] = useState();
    const [formattedPrice, setFormattedPrice] = useState(defaultFormattedPrice);
    const [minHourToBook, setMinHourToBook] = useState(null);
    // changing pricing for select activity
    const onChangeBookingTimeFormValues = (values) => {
        const { activity } = values || {};

        const selectedActivity =
            clusters?.find((_add) => _add.uuid === activity) || {};

        if (selectedActivity?.price) {
            const activityPrice = new Money(
                selectedActivity?.price?.amount,
                selectedActivity?.price?.currency
            );
            const activityFormattedPrice = priceData(
                activityPrice,
                intl
            )?.formattedPrice;
            if (activityFormattedPrice) {
                setFormattedPrice(activityFormattedPrice);
            }
        }

        if (selectedActivity?.minBookingDuration) {
            setMinHourToBook(Number(selectedActivity?.minBookingDuration));
        }
    };

    useEffect(() => {
        const bookingDataFromStorage = window.localStorage.getItem(BookingDataThroughSignUpKey);
        if (bookingDataFromStorage) {
            const storageData = JSON.parse(bookingDataFromStorage);
            const {
                bookingStartDate, bookingEndDate, ...rest
            } = storageData;

            setBookingDataFromSignup({
                bookingStartDate: { date: new Date(bookingStartDate) },
                bookingEndDate: { date: new Date(bookingEndDate) },
                ...rest
            });
            window.localStorage.removeItem(BookingDataThroughSignUpKey);
        };
    }, []);

    return (
        <div className={classes}>
            <ModalInMobile
                containerClassName={css.modalContainer}
                id="BookingTimeFormInModal"
                isModalOpenOnMobile={isBook}
                onClose={() => closeBookModal(history, location)}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}>
                <div className={css.modalHeading}>
                    <h1 className={css.title}>{title}</h1>
                </div>
                <div className={css.bookingHeading}>
                    {/* <div className={css.fromTitle}>
                        <FormattedMessage id='BookingPanel.from' />
                    </div> */}
                    {/* <div className={css.desktopPriceContainer}>
                        <div
                            className={css.desktopPriceValue}
                            title={priceTitle}>
                            {" "}{formattedPrice}
                        </div>
                        <div className={css.desktopPerUnit}>
                            <FormattedMessage id={unitTranslationKey} />
                        </div>
                    </div> */}
                    <div className={css.bookingHeadingContainer}>
                        <h2 className={titleClasses}>
                            {title} by {authorDisplayName}
                        </h2>
                        {/* {subTitleText ? (
                            <div className={css.bookingHelp}>
                                {subTitleText}
                            </div>
                        ) : null} */}

                        {!txIsSupported || isListingPagePanel || isInquiryAccepted || offerMade ? null : <div className={css.toggleInquiryFormIcon}>
                            {showInquiryForm ?
                                <CircleXIcon onClick={toggleShowInquiryForm} /> :
                                <PencilIcon onClick={toggleShowInquiryForm} />}
                        </div>}
                    </div>
                </div>

                {showBookingTimeForm ? (
                    <BookingTimeForm
                        className={css.bookingForm}
                        formId="BookingPanel"
                        submitButtonWrapperClassName={css.submitButtonWrapper}
                        unitType={unitType}
                        onSubmit={onSubmit}
                        price={price}
                        listing={listing}
                        listingId={listing.id}
                        isOwnListing={isOwnListing}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        startDatePlaceholder={intl.formatDate(
                            TODAY,
                            dateFormattingOptions
                        )}
                        endDatePlaceholder={intl.formatDate(
                            TODAY,
                            dateFormattingOptions
                        )}
                        timeZone={timeZone}
                        onFetchTransactionLineItems={
                            onFetchTransactionLineItems
                        }
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        onContactUser={onContactUser}
                        activities={listingActivities}
                        onChangeFormValues={onChangeBookingTimeFormValues}
                        minHour={minHourToBook}
                        currentUser={currentUser}
                        specialAddOns={specialAddOns}
                        isListingPagePanel={isListingPagePanel}
                        initialValues={bookingDataFromSignup ? bookingDataFromSignup : corruptValues ? {} : savedBookingData}
                    />
                ) : null}

                {customBreakdownComponent}
                {cancellationPolicyComponent}
            </ModalInMobile>

            <div className={css.openBookingForm}>
                <div className={css.priceContainer}>
                    <div className={css.priceValue} title={priceTitle}>
                        {formattedPrice}
                    </div>
                    <div className={css.perUnit}>
                        <FormattedMessage id={unitTranslationKey} />
                    </div>
                    {customBadgeComponent}
                </div>

                {showOpenPanelBtn ? (
                    (<div className={css.actionButtonsWrapper}>
                        <Button
                            rootClassName={isListingPagePanel ? css.enquireButton : css.bookButton}
                            onClick={() =>
                                openBookModal(
                                    isOwnListing,
                                    isClosed,
                                    history,
                                    location
                                )
                            }>
                            {isListingPagePanel ? <FormattedMessage id="BookingPanel.ctaButtonMessageEnquire" /> : <FormattedMessage id="BookingPanel.ctaButtonMessageBook" />}
                        </Button>
                    </div>)
                ) : isClosed ? (
                    <div className={css.closedListingButton}>
                        <FormattedMessage id="BookingPanel.closedListingButtonText" />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

BookingPanel.defaultProps = {
    rootClassName: null,
    className: null,
    titleClassName: null,
    isOwnListing: false,
    subTitle: null,
    unitType: config.bookingUnitType,
    monthlyTimeSlots: null,
    lineItems: null,
    fetchLineItemsError: null,
};

BookingPanel.propTypes = {
    rootClassName: string,
    className: string,
    titleClassName: string,
    listing: oneOfType([propTypes.listing, propTypes.ownListing]),
    isOwnListing: bool,
    unitType: propTypes.bookingUnitType,
    onSubmit: func.isRequired,
    title: oneOfType([node, string]).isRequired,
    subTitle: oneOfType([node, string]),
    authorDisplayName: oneOfType([node, string]).isRequired,
    onManageDisableScrolling: func.isRequired,
    onFetchTimeSlots: func.isRequired,
    monthlyTimeSlots: object,
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string,
    }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
