import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './OpenDropdownIcon.module.css';

const OpenDropdownIcon = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.54987 1.22786C8.24499 0.924539 7.75501 0.924539 7.45013 1.22786L1.22808 7.44991C0.923974 7.75479 0.923974 8.24634 1.22808 8.54966C1.53218 8.85454 2.0245 8.85454 2.32782 8.54966L8 2.87826L13.6722 8.54966C13.9763 8.85454 14.4686 8.85454 14.7719 8.54966C15.076 8.24634 15.076 7.75479 14.7719 7.44991L8.54987 1.22786Z"
                fill="#4A4A4A"
                stroke="#4A4A4A"
            />
        </svg>
    );
};

const { string } = PropTypes;

OpenDropdownIcon.defaultProps = {
    className: null,
    rootClassName: null,
};

OpenDropdownIcon.propTypes = {
    className: string,
    rootClassName: string,
};

export default OpenDropdownIcon;
