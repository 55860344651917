import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PersonIcon from '../../assets/icons/person-icon.svg';

const IconPerson = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={PersonIcon} alt="person icon" />;
};

const { string } = PropTypes;

IconPerson.defaultProps = {
    className: null,
    rootClassName: null,
};

IconPerson.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconPerson;
