/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './PropertyGroup.module.css';

const checkSelected = (options, selectedOptions) => {
    return options.map((option) => ({
        key: option.key,
        label: option.label,
        isSelected: includes(selectedOptions, option.key),
    }));
};

const IconCheck = (props) => {
    const isVisible = props.isVisible;
    const classes = isVisible
        ? css.checkIcon
        : classNames(css.checkIcon, css.hidden);

    return (
        <svg
            width="18"
            height="14"
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
        >
        <path
        className={css.marketplaceFill}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6402 0.231804C18.0645 0.585368 18.1218 1.21593 17.7682 1.64021L7.76822 13.6402C7.58835 13.856 7.32599 13.9863 7.04531 13.999C6.76464 14.0117 6.49156 13.9058 6.29289 13.7071L0.292893 7.70713C-0.0976311 7.31661 -0.0976311 6.68344 0.292893 6.29292C0.683418 5.90239 1.31658 5.90239 1.70711 6.29292L6.9328 11.5186L16.2318 0.359841C16.5853 -0.0644362 17.2159 -0.12176 17.6402 0.231804Z"
        fill="var(--grey3)"
        />
            {/* <path
                className={css.marketplaceFill}
                d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
                fillRule="evenodd"
            /> */}
        </svg>
    );
};

const Item = (props) => {
    const { label, isSelected } = props;
    const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
    return (
        <li className={css.item}>
            <span className={css.iconWrapper}>
                <IconCheck isVisible={isSelected} />
            </span>
            <div className={css.labelWrapper}>
                <span className={labelClass}>{label}</span>
            </div>
        </li>
    );
};

const PropertyGroup = (props) => {
    const {
        rootClassName,
        className,
        id,
        options,
        selectedOptions,
        twoColumns,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    const listClasses = twoColumns
        ? classNames(classes, css.twoColumns)
        : classes;

    const checked = checkSelected(options, selectedOptions);

    return (
        <ul className={listClasses}>
            {checked.map((option) => (
                <Item
                    key={`${id}.${option.key}`}
                    label={option.label}
                    isSelected={option.isSelected}
                />
            ))}
        </ul>
    );
};

PropertyGroup.defaultProps = {
    rootClassName: null,
    className: null,
    selectedOptions: [],
    twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyGroup.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    options: arrayOf(
        shape({
            key: string.isRequired,
            label: node.isRequired,
        })
    ),
    selectedOptions: arrayOf(string),
    twoColumns: bool,
};

export default PropertyGroup;
