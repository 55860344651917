import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CameraIcon from '../../assets/icons/camera-icon.svg';

const IconCamera = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={CameraIcon} alt="camera icon" />;
};

const { string } = PropTypes;

IconCamera.defaultProps = {
    className: null,
    rootClassName: null,
};

IconCamera.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconCamera;
