import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconSrc from '../../assets/icons/activities-icon.svg';

const IconActivities = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return <img className={classes} src={iconSrc} alt="puzzle icon" />;
};

const { string } = PropTypes;

IconActivities.defaultProps = {
    className: null,
    rootClassName: null,
};

IconActivities.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconActivities;
