import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconGift.module.css';
import Icon from '../../assets/icons/add-on-icon.svg';

const IconGift = (props) => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return <img className={classes} src={Icon} alt="add icon" />;
};

const { string } = PropTypes;

IconGift.defaultProps = {
    className: null,
    rootClassName: null,
};

IconGift.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconGift;
