/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import OpenDropdownIcon from '../../components/OpenDropdownIcon/OpenDropdownIcon';
import CloseDropdownIcon from '../../components/CloseDropdownIcon/CloseDropdownIcon';
import { Button } from '../../components';
import arrayMutators from 'final-form-arrays';
import { object, func } from 'prop-types';

import css from './EditListingClusterForm.module.css';
import { Form as FinalForm } from 'react-final-form';

import classNames from 'classnames';
import { compose } from 'redux';
import ClusterDetailsForm from './ClusterDetailsForm';
import ClusterActivityForm from './ClusterActivityForm';
import ClusterAddOnsForm from './ClusterAddOnsForm';
import { getAddOnTitleByUUID } from '../../data/addOns';

export const CLUSTER_SECTION = {
    DETAILS: 'details-section',
    ACTIVITIY: 'activity-section',
    ADD_ON: 'add_on-section',
};

// check details fields are all filled up
const clusterSubmitValidation = (_v) =>
    Object.values(_v || {}).filter((_v) => _v).length > 4;

const clusterFormSectionList = [
    {
        key: CLUSTER_SECTION.DETAILS,
        label: (
            <FormattedMessage id="EditListingAddOnForm.clusterTabDetailsSectionLabel" />
        ),
        disabledNextBtn: clusterSubmitValidation,
        nextSection: CLUSTER_SECTION.ACTIVITIY,
        component: ClusterDetailsForm,
        submitLabelId: 'EditListingAddOnForm.clusterNextActivitiesBtnText',
        previewRender: (_v) => (
            <p className={css.clusterFormSectionPreviewText}>
                Activity: {_v?.title}
            </p>
        ),
    },
    {
        key: CLUSTER_SECTION.ACTIVITIY,
        label: (
            <FormattedMessage id="EditListingAddOnForm.clusterTabActivitiesSectionLabel" />
        ),
        disabled: (_v) => clusterSubmitValidation(_v),
        disabledNextBtn: (_v) => clusterSubmitValidation(_v),
        nextSection: CLUSTER_SECTION.ADD_ON,
        component: ClusterActivityForm,
        submitLabelId: 'EditListingAddOnForm.clusterNextAddOnsBtnText',
        previewRender: (_v) => (
            <p className={css.clusterFormSectionPreviewText}>
                Activities:{' '}
                {_v?.activities_key
                    ? _v?.activities_key?.map((_a, i) => {
                          const comma =
                              i + 1 === _v?.activities_key?.length ? '' : ' ,';
                          return _a + comma;
                      })
                    : null}
            </p>
        ),
    },
    {
        key: CLUSTER_SECTION.ADD_ON,
        label: (
            <FormattedMessage id="EditListingAddOnForm.clusterTabAddOnsSectionLabel" />
        ),
        disabled: (_v) => clusterSubmitValidation(_v),
        disabledNextBtn: (_v) => clusterSubmitValidation(_v),
        component: ClusterAddOnsForm,
        submitLabelId: 'EditListingWizard.saveEditCluster',
        previewRender: (_v) => (
            <p className={css.clusterFormSectionPreviewText}>
                AddOns:{' '}
                {_v?.addOns
                    ? _v?.addOns?.map((_a, i) => {
                          const comma = i + 1 === _v?.addOns?.length ? '' : ',';
                          return (
                              getAddOnTitleByUUID(_a, _v?.clusterAddOns) + comma
                          );
                      })
                    : null}
            </p>
        ),
    },
];

const ClusterForm = (props) => {
    const {
        intl,
        handleOnCancelClusterForm,
        initialValues,
        openClusterSection,
        setOpenClusterSection,
        onSubmit,
        clusters,
    } = props;

    const toggleClusterSectionTab = (sectionName) => {
        setOpenClusterSection(
            openClusterSection === sectionName ? null : sectionName
        );
    };

    const renderSectionToggleIcon = (sectionName) =>
        openClusterSection === sectionName ? (
            <OpenDropdownIcon />
        ) : (
            <CloseDropdownIcon />
        );

    const cancelBtnLabel = intl.formatMessage({
        id: 'EditListingAddOnForm.cancelBtnLabel',
    });

    const clusterSaveBtnText = intl.formatMessage({
        id: 'EditListingAddOnForm.clusterSaveBtnText',
    });

    if (!initialValues) {
        return <></>;
    }

    return (
        <FinalForm
            {...props}
            mutators={{ ...arrayMutators }}
            render={(formRenderProps) => {
                const { disabled, invalid, updateInProgress, values, addOns } =
                    formRenderProps;

                const isClusterEdit = !values?.isDraft;
                const renderClusterFormActionBtn = (
                    disabledSubmit,
                    nextSection,
                    submitLabelId
                ) => {
                    const submitLabel = intl.formatMessage({
                        id: submitLabelId,
                    });

                    const submitInProgress = updateInProgress;
                    const subfieldsSubmitDisabled =
                        invalid || disabled || submitInProgress;
                    const submitDisabled = subfieldsSubmitDisabled;
                    const submitReady = false;

                    return (
                        <div className={css.activityDetailsSectionBtnContainer}>
                            <Button
                                type="button"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}
                                className={classNames(
                                    css.submitButton,
                                    css.subFieldsSubmitBtn,
                                    css.activityDetailsNextBtn
                                )}
                                onClick={() => {
                                    const _isClusterEdit = !values?.isDraft;
                                    const updateValues = {
                                        ...values,
                                        isDraft: !_isClusterEdit,
                                    };

                                    if (!_isClusterEdit) {
                                        updateValues.draftCluster = {
                                            uuid: values?.uuid,
                                            nextSection,
                                        };
                                    }

                                    if (!_isClusterEdit) {
                                        toggleClusterSectionTab(nextSection);
                                    }

                                    onSubmit({
                                        cluster: updateValues,
                                        submitFrom: openClusterSection,
                                    });

                                    if (
                                        openClusterSection ===
                                        CLUSTER_SECTION.ADD_ON
                                    ) {
                                        handleOnCancelClusterForm();
                                    }
                                }}>
                                {isClusterEdit ? 'Save' : submitLabel}
                            </Button>
                            <a
                                className={classNames(
                                    css.submitButton,
                                    css.subFieldsCancelBtn
                                )}
                                onClick={handleOnCancelClusterForm}>
                                {cancelBtnLabel}
                            </a>
                        </div>
                    );
                };

                return (
                    <div>
                        {React.Children.toArray(
                            clusterFormSectionList.map(
                                (
                                    {
                                        key,
                                        label,
                                        component: Component,
                                        disabled,
                                        disabledNextBtn,
                                        previewRender,
                                        ...rest
                                    },
                                    index
                                ) => {
                                    return (
                                        <div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    margin: '20px auto',
                                                    opacity:
                                                        invalid && disabled
                                                            ? '0.5'
                                                            : '1',
                                                }}
                                                onClick={() =>
                                                    invalid && disabled
                                                        ? {}
                                                        : toggleClusterSectionTab(
                                                              key
                                                          )
                                                }>
                                                <h3>{label}</h3>
                                                <div>
                                                    {renderSectionToggleIcon(
                                                        key
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    css.activityDetailsSectionContainer,
                                                    openClusterSection === key
                                                        ? css.show
                                                        : ''
                                                )}>
                                                <Component
                                                    toggleClusterSectionTab={
                                                        toggleClusterSectionTab
                                                    }
                                                    isClusterEdit={
                                                        isClusterEdit
                                                    }
                                                    cancelBtnLabel={
                                                        cancelBtnLabel
                                                    }
                                                    renderClusterFormActionBtn={
                                                        renderClusterFormActionBtn
                                                    }
                                                    clusterSaveBtnText={
                                                        clusterSaveBtnText
                                                    }
                                                    disabled={disabled}
                                                    disabledNextBtn={
                                                        disabledNextBtn
                                                    }
                                                    values={values}
                                                    clusters={clusters}
                                                    {...props}
                                                    {...rest}
                                                />
                                            </div>
                                            {openClusterSection !== key &&
                                                previewRender &&
                                                previewRender({
                                                    ...values,
                                                    clusterAddOns: addOns,
                                                })}
                                            {index + 1 !==
                                            clusterFormSectionList.length ? (
                                                <hr />
                                            ) : null}
                                        </div>
                                    );
                                }
                            )
                        )}
                    </div>
                );
            }}
        />
    );
};

ClusterForm.propTypes = {
    initialValues: object,
    isClusterExistFunc: func.isRequired,
    handleOnCancelClusterForm: func.isRequired,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(ClusterForm);
