const AMENITY_OPTIONS = [
    {
        key: 'wifi',
        label: 'Wifi',
    },
    {
        key: 'screen_with_projector',
        label: 'Screen with projector',
    },
    {
        key: 'tv_screen',
        label: 'TV screen',
    },
    {
        key: 'sound_system',
        label: 'Sound System',
    },
    {
        key: 'record_player',
        label: 'Record player',
    },
    {
        key: 'lounge',
        label: 'Lounge',
    },
    {
        key: 'air_conditioning',
        label: 'Air conditioning',
    },
    {
        key: 'outdoor_area',
        label: 'Outdoor Area',
    },
    {
        key: 'water_&_coffee',
        label: 'Water & coffee',
    },
    {
        key: 'grill',
        label: 'Grill',
    },
    {
        key: 'kitchen',
        label: 'Kitchen',
    },
    {
        key: 'coffee_machine',
        label: 'Coffee machine',
    },
    {
        key: 'dishes',
        label: 'Dishes',
    },
    {
        key: 'furnishing',
        label: 'Furnishing',
    },
    {
        key: 'flipchart',
        label: 'Flipchart',
    },
    {
        key: 'beamer',
        label: 'Beamer',
    },
    {
        key: 'whiteboard',
        label: 'Whiteboard',
    },
    {
        key: 'workshop_material',
        label: 'Workshop material',
    },
    {
        key: 'dj_desk',
        label: 'DJ Desk',
    },
    {
        key: 'clubbing_lights',
        label: 'Clubbing lights',
    },
    {
        key: 'wardrobe',
        label: 'Wardrobe',
    },
    {
        key: 'bedroom',
        label: 'Bedroom',
    },
    {
        key: 'bathroom(s)',
        label: 'Bathroom(s)',
    },
    {
        key: 'kitchen_equipment',
        label: 'Kitchen equipment',
    },
    {
        key: 'lighting_(direct_&_indirect)',
        label: 'Lighting (direct & indirect)',
    },
    {
        key: 'official_event_permit',
        label: 'Official event permit',
    },
    {
        key: 'bar',
        label: 'Bar',
    },
    {
        key: 'cleaning_service',
        label: 'Cleaning Service',
    },
    {
        key: 'electricity_&_heating',
        label: 'Electricity & Heating',
    },
    {
        key: 'toilets',
        label: 'Toilets',
    }
];

export default AMENITY_OPTIONS;
