const TYPE_OF_CHARGE = {
    PER_HOUR: 'perHour',
    PER_PERSON: 'perPerson',
    PER_PIECE: 'perPiece',
    PER_PIECE_PER_HOUR: 'perPiecePerHour',
    PER_PERSON_PER_HOUR: 'perPersonPerHour',
    PER_BOOKING: 'perBooking',
};

const CHARGE_DISPLAY = {
    perHour: "per hour",
    perPerson: "per person",
    perPiece: "per piece",
    perPiecePerHour: "per piece per hour",
    perPersonPerHour: "per person per hour",
    perBooking: "per booking",
}

const TYPE_OF_CHARGE_OPTIONS = Object.values(TYPE_OF_CHARGE);

const getIntlTypeOfChargeOptionLabel = (key, intl) =>
    intl.formatMessage({
        id: `EditListingAddOnForm.typeOfChargeOption.${key}`,
    });

export {
    TYPE_OF_CHARGE,
    CHARGE_DISPLAY,
    TYPE_OF_CHARGE_OPTIONS,
    getIntlTypeOfChargeOptionLabel,
};
