import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import tooltipIcon from "./TooltipIcon.png";
import css from './Tooltip.module.css';

const Tooltip = props => {
    const { messageId, icon, intl } = props;

    const tooltipMessageText = intl.formatMessage(
        { id: messageId }
    );

    return <div className={css.tooltip}>
        {icon ? icon : <img src={tooltipIcon} />}
        <span className={css.tooltiptext}>
            {tooltipMessageText}
        </span>
    </div>
};

export default compose(injectIntl)(Tooltip);
